import { Auth, Hub } from "aws-amplify";
import { Socket } from "phoenix";
import React, { useContext, useEffect, useState } from "react";
import SocketHandler from "./SocketHandler";

import config from "../../config.json";

const SocketContext = React.createContext<SocketHandler | undefined>(undefined);

const SocketProvider = (props: { children: React.ReactNode }) => {
  const [once] = useState(null);
  const [socketHandler, setSocketHandler] = useState<SocketHandler | undefined>(
    undefined
  );
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchToken = async () => {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setAuthToken(token);
    };

    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("SocketProvider auth event: ", event, data);
      fetchToken();
    });

    fetchToken();

    return unsubscribe;
  }, [once]);

  const kickSession = async () => {
    await Auth.currentSession();
  };

  useEffect(() => {
    const connect = async () => {
      const token = authToken;
      if (!token) {
        return;
      }

      const params = { token: token };
      const socket = new Socket(config.ws_url, { params });

      socket.onOpen(() => {
        setSocketHandler(new SocketHandler(socket));
      });

      socket.onClose((cb) => {
        console.log("Closed: ", cb);
      });

      socket.onError((cb) => {
        console.log("Error: ", cb);

        kickSession();
      });

      socket.connect();
    };

    connect();
  }, [authToken]);

  return (
    <SocketContext.Provider value={socketHandler}>
      {props.children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  return useContext(SocketContext);
};

export default SocketProvider;
export { useSocket };
