import React, { Fragment, useRef, useState } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useHotkeys } from "react-hotkeys-hook";

import SearchPanel from "./SearchPanel";

const SearchButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const searchFieldRef = useRef(null);

  useHotkeys("command+enter", () => setIsOpen(!isOpen), {
    enableOnTags: ["INPUT"],
  });

  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)}>
        <SearchIcon className="h-10 rounded-md p-2 text-stone-800 hover:bg-black/[0.05]  active:hover:bg-black/[0.1] dark:text-neutral-400 dark:hover:bg-white/[0.05] dark:active:hover:bg-white/[0.1]" />
      </button>

      <Transition show={isOpen} as={Fragment}>
        <Dialog onClose={() => setIsOpen(false)} initialFocus={searchFieldRef}>
          <Transition.Child
            as={Fragment}
            enter="ease duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/40 dark:bg-black/60"
              aria-hidden="true"
            />
          </Transition.Child>

          <div className="fixed inset-0 flex p-5 sm:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease duration-300"
              enterFrom="opacity-0 -translate-y-5 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 -translate-y-5 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mx-auto h-fit w-full sm:max-w-md">
                <SearchPanel
                  onClose={() => setIsOpen(false)}
                  searchFieldRef={searchFieldRef}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SearchButton;
