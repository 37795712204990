import { API } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Combobox } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import { SearchResult } from "./SearchResult";
import SearchResultRow from "./SearchResultRow";

type SearchPanelProps = {
  onClose: () => void;
  searchFieldRef: React.MutableRefObject<null>;
};

const SearchPanel = (props: SearchPanelProps) => {
  const [selectedResult] = useState<SearchResult | undefined>(undefined);
  const clickedResult = useRef<SearchResult | undefined>(undefined);
  const [searchString, setSearchString] = useState("");
  const [results, setResults] = useState<SearchResult[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchString === "") {
      setResults([]);
      return;
    }

    const promise = API.get("dashboard", `/search?q=${searchString}`, {});

    const fetchData = async () => {
      try {
        const result = await promise;
        setResults(result);
      } catch (error) {
        if (API.isCancel(error)) {
          // cancelled because a newer search was started
        } else {
          console.log("Error: ", error);
        }
      }
    };

    fetchData();

    return () => {
      API.cancel(promise, "cancelled");
    };
  }, [searchString]);

  const selectItem = (result: SearchResult) => {
    if (result === clickedResult.current) {
      props.onClose();
      return;
    }

    navigate(linkForResult(result));
    props.onClose();
  };

  const keyForResult = (result: SearchResult): React.Key => {
    switch (result.type) {
      case "cell":
        return result.serialNumber;
      case "user":
        return result.syngID;
    }
  };

  const linkForResult = (result: SearchResult): string => {
    switch (result.type) {
      case "cell":
        if (result.placeID) {
          return `/places/${result.placeID}/cells/${result.serialNumber}`;
        } else if (result.roomID) {
          return "/rooms/" + result.roomID;
        } else {
          return "/cells/" + result.serialNumber;
        }
      case "user":
        return "/users/" + result.syngID;
    }
  };

  const resultClicked = (result: SearchResult) => {
    clickedResult.current = result;
  };

  return (
    <>
      <div className="relative overflow-hidden rounded-xl bg-white dark:border dark:border-neutral-600 dark:bg-neutral-700 dark:shadow-xl">
        <Combobox value={selectedResult} onChange={selectItem}>
          <Combobox.Input
            ref={props.searchFieldRef}
            value={searchString}
            placeholder="Search"
            className="h-12 w-full border-0 bg-white px-9 text-lg font-medium placeholder:text-stone-400 focus:ring-0 dark:bg-neutral-700 dark:text-neutral-100 dark:placeholder:text-neutral-400 sm:w-[30rem]"
            onChange={(event) => setSearchString(event.target.value)}
          />
          <SearchIcon className="absolute top-3.5 left-2.5 h-5 text-stone-400 dark:text-neutral-400" />

          {results.length !== 0 && (
            <Combobox.Options
              static
              className="border-t border-stone-300 p-1.5 dark:border-neutral-600"
            >
              {results.map((result) => (
                <Combobox.Option
                  as={Link}
                  to={linkForResult(result)}
                  key={keyForResult(result)}
                  value={result}
                  onClick={() => resultClicked(result)}
                >
                  {({ active }) => (
                    <SearchResultRow result={result} active={active} />
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </Combobox>
      </div>
    </>
  );
};

export default SearchPanel;
