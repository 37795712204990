import { Fragment } from "react";
import { usePlaceData } from "../data/PlaceDataProvider";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import CellRow from "./CellRow";

const CellTable = () => {
  const placeData = usePlaceData();
  const name = placeData.isLoading ? "XXXXXXXX" : placeData.name;
  const rooms = Object.keys(placeData.rooms).map((key) => placeData.rooms[key]);

  return (
    <div className="divide-y divide-stone-100 dark:divide-neutral-600">
      <div className="bg-white px-3 py-3 dark:bg-neutral-700 sm:px-6">
        <Label
          size={ControlSize.Large}
          title={name}
          loading={placeData.isLoading}
        />
      </div>
      <div className="min-w-full bg-white dark:bg-neutral-800 ">
        {rooms.map((room) => (
          <Fragment key={room.roomID}>
            <div className="bg-stone-100 dark:bg-neutral-800">
              <span className="px-2 text-left text-sm font-semibold">
                <Label title={room.name} />
              </span>
            </div>

            {room.cellIdentifiers.map((serialNumber) => (
              <CellRow
                key={serialNumber}
                cell={placeData.cells[serialNumber]}
              />
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default CellTable;
