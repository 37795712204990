import { useParams } from "react-router-dom";

import ControlSize from "./controls/ControlSize";
import Label from "./controls/Label";
import LoadingContext from "./controls/LoadingContext";
import WiFiIndicator from "./controls/WiFiIndicator";

import { placeholderCellData, useCellData } from "./data/CellData";
import RoomData, {
  placeholderRoomData,
  useRoomCells,
  useRoomData,
} from "./data/RoomData";

type RoomInfoProps = {
  room: RoomData;
};

const RoomInfo = (props: RoomInfoProps) => {
  const cellList = useRoomCells(props.room.room_id);
  const currentCells = cellList.state === "LOADED" ? cellList.value : [""];
  const isCellListLoading = cellList.state === "LOADING";

  return (
    <aside className="m-auto mt-8 w-96 divide-y divide-stone-100 overflow-hidden rounded-xl bg-white shadow dark:divide-neutral-600 dark:border dark:border-white/[0.01] dark:bg-neutral-700 dark:shadow-[0_10px_15px_-3px_rgba(0,0,0,0.15)]">
      <div className="px-4 py-5 sm:px-6">
        <Label size={ControlSize.Large} title={props.room.name} />
      </div>
      <div
        className="bg-stone-50 px-4 py-2 dark:bg-neutral-800 sm:px-6"
        onClick={() => console.log("toggle advanced")}
      >
        <LoadingContext.Provider value={isCellListLoading}>
          <table className="min-w-full">
            <thead>
              <tr className="text-left">
                <th scope="col"></th>
                <th scope="col" className="text-sm font-semibold">
                  Serial Number
                </th>
                <th scope="col" className="text-sm font-semibold">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {currentCells.map((serialNumber) => (
                <CellRow key={serialNumber} serialNumber={serialNumber} />
              ))}
            </tbody>
          </table>
        </LoadingContext.Provider>
      </div>
    </aside>
  );
};

const CellRow = (props: { serialNumber: string }) => {
  const cellData = useCellData(props.serialNumber);
  const currentCellData =
    cellData.state === "LOADED" ? cellData.value : placeholderCellData;
  const isLoading = cellData.state === "LOADING";

  return (
    <LoadingContext.Provider value={isLoading}>
      <tr>
        <td>
          <WiFiIndicator
            rssi={currentCellData.rssi ?? 0}
            channel={currentCellData.wifi_channel ?? 0}
            isOnline={currentCellData.online}
          />
        </td>
        <td>
          <Label loading={false} title={props.serialNumber} />
        </td>
        <td>
          <Label title={currentCellData.role ?? ""} />
        </td>
      </tr>
    </LoadingContext.Provider>
  );
};

type RoomParams = {
  roomID: string;
};

function Room() {
  const { roomID } = useParams<RoomParams>();
  const roomData = useRoomData(roomID ?? "");

  const currentRoomData =
    roomData.state === "LOADED" ? roomData.value : placeholderRoomData;
  const isLoading = roomData.state === "LOADING";

  return (
    <>
      <LoadingContext.Provider value={isLoading}>
        <RoomInfo room={currentRoomData} />
      </LoadingContext.Provider>
    </>
  );
}

export default Room;
