import { useCallback } from "react";
import { useTopicEvent } from "../socket/ChannelProvider";

const WebSocket = () => {
  const callback = useCallback((cell_state) => {
    console.log("Cell state changed: ", cell_state);
  }, []);
  useTopicEvent("cell:6JEZFLXNLY5CV712S", "changed", callback);

  return <>test</>;
};

export default WebSocket;
