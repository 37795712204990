enum Role {
  primary = "primary",
  secondary = "secondary",
  configuring = "configuring",
}

type Cell = {
  serialNumber: string;
  model?: string;

  placeID?: string;
  roomID?: string;

  role: Role;
  rolePinned: boolean;

  isOnline: boolean;
  lastSeen?: Date;
  ssid?: string;
  bssid?: string;
  ipAddress?: string;
  rssi?: number;
  wifiChannel?: number;

  isCalibrated: boolean;
  isSyFiGood: boolean;
  isTimeSyncGood: boolean;

  syngOSVersion?: string;
  ota?: {
    code: number;
    name: string;
    progress: number;
    size: number;
    status:
      | "downloading"
      | "installing"
      | "rebooting"
      | "success"
      | "failure"
      | "already_installed"
      | "aborted";
    updateMode: "manual" | "notify" | "automatic";
  };

  position: {
    x: number;
    y: number;
    z: number;
    rotation: number;
  };
};

const placeholderCellData: Cell = {
  serialNumber: "XXXXXXXXXXXXXXXXX",
  isOnline: true,
  syngOSVersion: "Troubadour-1.4",
  role: Role.configuring,
  rolePinned: false,
  ssid: "SYNG",
  rssi: -50,
  wifiChannel: 149,
  position: { x: 0, y: 0, z: 0, rotation: 0 },
  isCalibrated: false,
  isSyFiGood: false,
  isTimeSyncGood: false
};

export default Cell;
export { Role, placeholderCellData };
