import React from "react";

const Card = (props: { className?: string; children: React.ReactNode }) => {
  return (
    <div
      className={`overflow-hidden rounded-2xl bg-white shadow dark:border dark:border-white/[0.01] dark:bg-neutral-800 dark:shadow-none sm:rounded-xl dark:sm:shadow-[0_10px_15px_-3px_rgba(0,0,0,0.25)] ${
        props.className ?? ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default Card;
