import React, { useState } from "react";
import ControlSize from "../controls/ControlSize";
import Checkbox from "../controls/Checkbox";
import LoadingContext from "../controls/LoadingContext";

type PartialState = {
  checked: boolean;
  disabled: boolean;
  description: string;
};

export default function Buttons() {
  const [isLoading, setIsLoading] = useState(false);
  const controlSizes: ControlSize[] = [
    ControlSize.Large,
    ControlSize.Medium,
    ControlSize.Small,
  ];
  const columns: PartialState[] = [
    { checked: false, disabled: false, description: "Base" },
    { checked: false, disabled: true, description: "Disabled" },
    { checked: true, disabled: false, description: "Checked" },
    { checked: true, disabled: true, description: "Checked Disabled" },
  ];

  const onChange = (_value: boolean) => {};

  return (
    <>
      <div className="min-w-full px-3">
        <LoadingContext.Provider value={isLoading}>
          <table className="min-w-full table-fixed text-center">
            <thead>
              <tr>
                {columns.map((state) => (
                  <th key={state.description}>{state.description}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {controlSizes.map((size) => (
                <tr key={size}>
                  {columns.map((state) => (
                    <td key={state.description} className="py-2">
                      <Checkbox
                        value={state.checked}
                        disabled={state.disabled}
                        size={size}
                        title="Checkbox"
                        onChange={onChange}
                      />
                      {/* <Button primary={state.primary} disabled={state.disabled} size={size}>Button</Button> */}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingContext.Provider>
        <Checkbox
          title="Loading"
          id="loading"
          value={isLoading}
          onChange={setIsLoading}
        />
      </div>
    </>
  );
}
