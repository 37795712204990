import ControlSize from "./ControlSize";

type SwitchState = {
  value: boolean;
  disabled: boolean;
  size: ControlSize;
  loading: boolean;
};

type SwitchStyleFactory = {
  background: (state: SwitchState) => string;
  foreground: (state: SwitchState) => string;
};

const defaultBackground = (state: SwitchState) => {
  var styles: string[] = [];

  styles.push(
    "relative inline-flex items-center flex-none rounded-full transition-colors duration-200 ease"
  );

  switch (state.size) {
    case ControlSize.Small:
      styles.push("w-10 h-6 sm:w-8 sm:h-5");
      break;
    case ControlSize.Medium:
      styles.push("w-12 h-7 sm:w-10 sm:h-6");
      break;
    case ControlSize.Large:
      styles.push("w-14 h-8 sm:w-12 sm:h-7");
      break;
  }

  if (state.loading) {
    styles.push("animate-pulse bg-stone-300");
    styles.push("dark:bg-neutral-500/[0.5]");
  } else {
    styles.push(state.value ? "bg-yellow-400" : "bg-stone-300/[0.7]");
    styles.push(
      state.value ? "dark:bg-yellow-500" : "dark:bg-neutral-500/[0.7]"
    );

    if (state.disabled) {
      styles.push("opacity-50");
    } else {
      styles.push(
        state.value ? "hover:active:bg-yellow-500" : "hover:active:bg-stone-300"
      );
      styles.push(
        state.value
          ? "dark:hover:active:bg-yellow-400"
          : "dark:hover:active:bg-neutral-500"
      );
    }
  }

  return styles.join(" ");
};

const defaultForeground = (state: SwitchState) => {
  var styles: string[] = [];

  if (state.loading) {
    styles.push("opacity-0");
  }

  styles.push("bg-white rounded-full shadow transition-all duration-200 ");

  switch (state.size) {
    case ControlSize.Small:
      styles.push("w-5 h-5 sm:w-4 sm:h-4");
      styles.push(
        state.value
          ? "translate-x-[1.125rem] sm:translate-x-[.875rem]"
          : "translate-x-0.5"
      );
      // styles.push(state.disabled ? "" : "group-hover:group-active:w-5");
      // styles.push(state.disabled || !state.value ? "" : "group-hover:group-active:translate-x-[.625rem]");
      break;
    case ControlSize.Medium:
      styles.push("w-6 h-6 sm:w-5 sm:h-5");
      styles.push(
        state.value
          ? "translate-x-[1.375rem] sm:translate-x-[1.125rem]"
          : "translate-x-0.5"
      );
      // styles.push(state.disabled ? "" : "group-hover:group-active:w-[1.625rem]");
      // styles.push(state.disabled || !state.value ? "" : "group-hover:group-active:translate-x-[0.75rem]");
      break;
    case ControlSize.Large:
      styles.push("w-7 h-7 sm:w-6 sm:h-6");
      styles.push(
        state.value
          ? "translate-x-[1.625rem] sm:translate-x-[1.375rem]"
          : "translate-x-0.5"
      );
      // styles.push(state.disabled ? "" : "group-hover:group-active:w-[1.9375rem]");
      // styles.push(state.disabled || !state.value ? "" : "group-hover:group-active:translate-x-[.9375rem]");
      break;
  }

  return styles.join(" ");
};

const defaultSwitchStyle: SwitchStyleFactory = {
  background: defaultBackground,
  foreground: defaultForeground,
};

export type { SwitchState, SwitchStyleFactory };
export { defaultSwitchStyle };
