import {
  SearchResult,
  SearchResultCell,
  SearchResultUser,
} from "./SearchResult";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import { formatPhoneNumber } from "../User";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type SearchResultRowProps = {
  result: SearchResult;
  active: boolean;
};

const CellRow = (props: { result: SearchResultCell; active: boolean }) => {
  return (
    <div className="flex flex-col">
      <Label
        title={props.result.serialNumber}
        selectedBackground={props.active}
        size={ControlSize.Large}
      />
      <Label
        secondary
        title="Cell Alpha"
        selectedBackground={props.active}
        size={ControlSize.Medium}
      />
    </div>
  );
};

const UserRow = (props: { result: SearchResultUser; active: boolean }) => {
  const user = props.result;
  const nameParts = [user.firstName, user.lastName]
    .filter((s) => s !== undefined)
    .map((s) => s ?? "");
  const fullName = nameParts.join(" ");

  if (fullName.length === 0) {
    return (
      <div className="flex flex-col">
        <Label
          title={props.result.phoneNumber}
          selectedBackground={props.active}
          size={ControlSize.Large}
        />
        <Label
          secondary
          title="User"
          selectedBackground={props.active}
          size={ControlSize.Medium}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Label
        title={fullName}
        selectedBackground={props.active}
        size={ControlSize.Large}
      />
      <Label
        secondary
        title={formatPhoneNumber(props.result.phoneNumber)}
        selectedBackground={props.active}
        size={ControlSize.Medium}
      />
    </div>
  );
};

const SearchResultRow = (props: SearchResultRowProps) => {
  return (
    <div
      className={classNames(
        props.active ? "bg-yellow-500" : "",
        "rounded-lg px-3 py-2 sm:px-3 sm:py-1"
      )}
    >
      {props.result.type === "cell" && (
        <CellRow result={props.result} active={props.active} />
      )}
      {props.result.type === "user" && (
        <UserRow result={props.result} active={props.active} />
      )}
    </div>
  );
};

export default SearchResultRow;
