import { Link } from "react-router-dom";
import config from "../../config.json";
import { currentVersion, DashboardVersion } from "./DashboardVersion";
import EnvDialog from "./EnvDialog";

function versionDescription(version: DashboardVersion): string {
  switch (version.type) {
    case "local":
      return "local";
    case "build":
      return version.commitShortHash;
  }
}

const EnvButton = () => {
  return (
    <>
      <Link to="?envDialog" className="text-center font-mono text-xs">
        <div className="mt-2">{config.deployment_name}</div>
        <div>{versionDescription(currentVersion)}</div>
      </Link>
      <EnvDialog />
    </>
  );
};

export default EnvButton;
