import React, { useContext } from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import {
  defaultSwitchStyle,
  SwitchState,
  SwitchStyleFactory,
} from "./SwitchStyleFactory";
import ControlSize from "./ControlSize";
import Label from "./Label";
import { defaultLabelStyle, LabelStyleFactory } from "./LabelStyleFactory";
import LoadingContext from "./LoadingContext";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type SwitchProps = {
  title?: string;
  description?: string;
  value: boolean;
  disabled: boolean;
  size: ControlSize;
  fullWidth: boolean;
  labelPosition: "leading" | "trailing";
  loading?: boolean;
  onChange?: (newValue: boolean) => void;
  style: SwitchStyleFactory;
  labelStyle: LabelStyleFactory;
};

const Switch = (props: SwitchProps) => {
  const handleChange = (checked: boolean) => {
    if (props.onChange) {
      props.onChange(checked);
    }
  };

  const isLoadingViaContext = useContext(LoadingContext);
  const isLoading = props.loading ?? isLoadingViaContext;

  const state: SwitchState = {
    value: props.value,
    disabled: props.disabled,
    size: props.size,
    loading: isLoading,
  };

  return (
    <>
      <HeadlessSwitch.Group
        as="div"
        className={classNames(
          "group relative flex touch-manipulation items-center gap-x-4 sm:gap-x-2",
          props.labelPosition === "leading" ? "flex-row-reverse" : "",
          props.fullWidth ? "justify-between" : ""
        )}
      >
        {isLoading && (
          <HeadlessSwitch
            checked={props.value}
            disabled={props.disabled}
            onChange={handleChange}
            className={props.style.background(state)}
          >
            <span className={props.style.foreground(state)} />
          </HeadlessSwitch>
        )}
        {!isLoading && (
          <HeadlessSwitch
            checked={props.value}
            disabled={props.disabled}
            onChange={handleChange}
            className={props.style.background(state)}
          >
            <span className={props.style.foreground(state)} />
          </HeadlessSwitch>
        )}
        <HeadlessSwitch.Label as="div">
          {props.title && (
            <Label
              title={props.title}
              secondary={false}
              disabled={isLoading || props.disabled}
              size={props.size}
              loading={isLoading}
              style={props.labelStyle}
            />
          )}
          {props.description && (
            <HeadlessSwitch.Description
              className={props.labelStyle({
                secondary: true,
                selectedBackground: false,
                disabled: isLoading || props.disabled,
                mono: false,
                size: props.size,
                loading: isLoading,
              })}
            >
              {props.description}
            </HeadlessSwitch.Description>
          )}
        </HeadlessSwitch.Label>
      </HeadlessSwitch.Group>
    </>
  );
};

Switch.defaultProps = {
  disabled: false,
  size: ControlSize.Medium,
  fullWidth: false,
  labelPosition: "trailing",
  loading: null,
  title: null,
  description: null,
  style: defaultSwitchStyle,
  labelStyle: defaultLabelStyle,
};

export { defaultSwitchStyle };
export default Switch;
