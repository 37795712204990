import ControlSize from "./ControlSize";

type ButtonState = {
  primary: boolean;
  disabled: boolean;
  size: ControlSize;
  fullWidth: boolean;
  capsule: boolean;
  loading: boolean;
};

type ButtonStyleFactory = (state: ButtonState) => string;

const defaultButtonStyle: ButtonStyleFactory = (state) => {
  var styles: string[] = [];

  styles.push(state.fullWidth ? "w-full" : "w-fit");
  styles.push("inline-flex items-baseline justify-center ring-1 ");

  if (state.loading) {
    styles.push("animate-pulse text-transparent");
  } else {
    styles.push("shadow disabled:opacity-50 dark:disabled:opacity-30");
  }

  switch (state.size) {
    case ControlSize.Small:
      styles.push(
        "px-4 sm:px-2 py-1 sm:py-0.5 text-base sm:text-xs",
        state.capsule ? "" : "rounded-md sm:rounded"
      );
      break;
    case ControlSize.Medium:
      styles.push(
        "px-6 sm:px-4 py-2 sm:py-1.5 text-lg sm:text-sm",
        state.capsule ? "" : "rounded-lg sm:rounded-md"
      );
      break;
    case ControlSize.Large:
      styles.push(
        "px-8 py-2 sm:py-1.5 text-xl sm:text-base",
        state.capsule ? "" : "rounded-[0.625rem] sm:rounded-lg"
      );
      break;
  }

  if (state.capsule) {
    styles.push("rounded-full");
  }

  if (state.primary) {
    styles.push("font-semibold");

    if (state.loading) {
      styles.push("bg-yellow-300 ring-yellow-300");
      styles.push("dark:bg-yellow-400 dark:ring-yellow-400");
    } else {
      styles.push(
        "bg-yellow-400 ring-yellow-600/[0.75] shadow-yellow-400 text-yellow-700"
      );
      styles.push(
        "dark:bg-yellow-500 dark:ring-yellow-600/[0.5] dark:shadow-yellow-900 dark:text-yellow-900"
      );

      if (!state.disabled) {
        styles.push("hover:active:bg-yellow-500");
        styles.push("dark:hover:active:bg-yellow-400");
      }
    }
  } else {
    styles.push("font-medium");

    if (state.loading) {
      styles.push("bg-stone-300 ring-stone-300");
      styles.push("dark:bg-neutral-500/[0.5] dark:ring-neutral-500/[0.5]");
    } else {
      styles.push("bg-white ring-black/[0.08]");
      styles.push("dark:bg-neutral-500/[0.5] dark:ring-black/[0.18]");
      styles.push("dark:text-neutral-200");
      if (!state.disabled) {
        styles.push("hover:active:bg-stone-100");
        styles.push("dark:hover:active:bg-neutral-500/[0.75]");
      }
    }
  }

  return styles.join(" ");
};

export type { ButtonState, ButtonStyleFactory };
export { defaultButtonStyle };
