import { NavLink, useParams } from "react-router-dom";
import { usePlaceData } from "../data/PlaceDataProvider";
import Cell from "../data/Cell";
import { ReferenceCircle } from "../data/Room";

import { ReactComponent as CellIcon } from "../../assets/svg/CellIndicator.svg";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Point = {
  x: number;
  y: number;
};

function convertFromPhysicalToInterface(
  physical: Point,
  referenceCircle: ReferenceCircle
): Point {
  const radius =
    referenceCircle.radius > 0 ? referenceCircle.radius / 0.85 : 1.0;
  const x = (physical.x - referenceCircle.x) / radius;
  const y = (physical.y - referenceCircle.y) / radius;

  const radians = (-referenceCircle.rotation * Math.PI) / 180.0;
  const c = Math.cos(radians);
  const s = Math.sin(radians);

  const audioPosition = {
    x: x * c + y * -s,
    y: x * s + y * c,
  };

  return {
    x: -audioPosition.y,
    y: -audioPosition.x,
  };
}

const CellIndicator = (props: {
  cell: Cell;
  referenceCircle: ReferenceCircle;
  linkBase?: string;
}) => {
  const point = convertFromPhysicalToInterface(
    props.cell.position,
    props.referenceCircle
  );
  const x = (point.x + 1.0) * 50;
  const y = (point.y + 1.0) * 50;
  const containerStyle = {
    width: `${x}%`,
    height: `${y}%`,
  };

  const unselectedClass = "text-stone-300 dark:text-neutral-500";
  const selectedClass = "text-stone-500 dark:text-neutral-200";

  return (
    <div className="pointer-events-none absolute" style={containerStyle}>
      <NavLink to={`${props.linkBase ?? ""}${props.cell.serialNumber}`}>
        {({ isActive }) => (
          <CellIcon
            className={classNames(
              "pointer-events-auto absolute -right-4 -bottom-4 h-8 w-8",
              isActive ? selectedClass : unselectedClass
            )}
            title={props.cell.serialNumber}
          />
        )}
      </NavLink>
    </div>
  );
};

const SpaceMap = (props: { linkBase?: string }) => {
  const { serialNumber } = useParams<{ serialNumber: string }>();
  const placeData = usePlaceData();

  var cells: Cell[] = [];
  var referenceCircle: ReferenceCircle = { x: 0, y: 0, radius: 1, rotation: 0 };

  if (serialNumber) {
    const cell = placeData.cells[serialNumber];
    if (cell && cell.roomID) {
      const room = placeData.rooms[cell.roomID];
      if (room) {
        referenceCircle = room.referenceCircle ?? referenceCircle;
        cells = room.cellIdentifiers.map((sn) => placeData.cells[sn]);
      }
    }
  }

  return (
    <div className="p-4">
      <div className="relative aspect-square rounded-full border border-stone-200 bg-stone-100 dark:border-neutral-600 dark:bg-neutral-700">
        {cells.map((cell) => (
          <CellIndicator
            key={cell.serialNumber}
            cell={cell}
            referenceCircle={referenceCircle}
            linkBase={props.linkBase}
          />
        ))}
      </div>
    </div>
  );
};

export default SpaceMap;
