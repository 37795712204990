import { useCallback, useState } from "react";
import useQuery from "../utils/useQuery";

import Card from "./Card";
import Label from "./controls/Label";


const TextProperty = ({ value }: { value?: string }) => {
  const isMissing = value === undefined;
  return (
    <Label secondary={isMissing} disabled={isMissing} title={value ?? "—"} />
  );
};

type RowProps = {
  title: string;
  value?: string;
  children?: React.ReactNode;
};

const Row = (props: RowProps) => {
  return (
    <div className="flex justify-between py-3 text-sm font-medium">
      <dt>
        <Label secondary loading={undefined} title={props.title} />
      </dt>
      <dd className="flex-grow text-right">
        {props.children || <TextProperty value={props.value} />}
      </dd>
    </div>
  );
};

function formatPart(part: number, revision: number) {
  const revStr = ` (revision ${revision})`;
  var str = part.toString();

  if (str.length > 3) {
    str = `${str.slice(0, 3)}-${str.slice(3)}`;
  }

  return str + revStr;
}

function formatDate(year: number, week: number) {
  return `20${year}-W${week.toString().padStart(2, "0")}`;
}

class SerialNumber {
  sequence: number;
  vendor: number;
  revision: number;
  part: number;
  week: number;
  year: number;

  constructor(str: string) {
    var sum = BigInt(0);

    const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    for (const c of str) {
      const i = alphabet.indexOf(c.toUpperCase());
      if (i >= 0) {
        sum *= BigInt(36);
        sum += BigInt(i);
      }
    }

    function extractBits(count: number) {
      const result = sum & BigInt((1 << count) - 1);
      sum >>= BigInt(count);
      return Number(result);
    }

    this.sequence = extractBits(23);
    this.vendor = extractBits(14);
    this.revision = extractBits(11);
    this.part = extractBits(27);
    this.week = extractBits(6);
    this.year = extractBits(7);
  }
};

const SerialNumberInfo = () => {
  const query = useQuery();
  const [serialNumber, setSerialNumber] = useState(
    query.get("serialNumber") ?? ""
  );

  const serialNumberChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSerialNumber(event.currentTarget.value);
    },
    [setSerialNumber]
  );

  const parsed = new SerialNumber(serialNumber);

  return (
    <Card className="m-auto mt-8 w-96">
    <aside className="divide-y divide-stone-100 overflow-hidden bg-white dark:divide-neutral-600 dark:bg-neutral-700">
    <div className="px-3 py-3 flex">
      <input
        placeholder="Serial Number"
        value={serialNumber}
        onChange={serialNumberChanged}
        className="flex-grow rounded-lg bg-white px-6 py-2 !pl-3 !pr-1 text-lg font-medium shadow ring-1 ring-black/[0.08] focus:outline-none disabled:opacity-50 dark:bg-neutral-500/[0.5] dark:text-neutral-200 dark:placeholder:text-neutral-500 dark:ring-black/[0.18] dark:disabled:opacity-30 sm:rounded-md sm:px-4 sm:py-1.5 sm:text-sm"
      />
    </div>
    <div
      className="bg-stone-50 px-4 py-2 dark:bg-neutral-800 sm:px-6"
      onClick={() => console.log("toggle advanced")}
    >
      <dl className="divide-y divide-stone-200 dark:divide-neutral-700">
        <Row title="Part" value={formatPart(parsed.part, parsed.revision)} />
        <Row title="Date" value={formatDate(parsed.year, parsed.week)} />
        <Row title="Sequence" value={parsed.sequence.toString()} />
        <Row title="Vendor" value={parsed.vendor.toString()} />
      </dl>
    </div>
    </aside>
    </Card>);
}

export default SerialNumberInfo;
