import { useContext } from "react";
import { useParams } from "react-router-dom";
import { Routes, Route, useHref } from "react-router-dom";
import AuthProvider from "../AuthProvider";
import PlaceDataProvider from "../data/PlaceDataProvider";
import Card from "../Card";
import CellPanel from "./CellPanel";
import CellTable from "./CellTable";
import PlaceUserLinks from "./PlaceUserLinks";
import SpaceMap from "./SpaceMap";


const Place = () => {
  const { placeID } = useParams<{ placeID: string }>();
  const cellsHref = useHref("cells/");
  const currentUser = useContext(AuthProvider.Context);
  const canReadCustomerData = currentUser && currentUser.canReadCustomerData;

  return (
    <PlaceDataProvider placeID={placeID ?? ""}>
      <div className="m-auto grid max-w-6xl grid-cols-3 gap-4 px-8 pb-8">
        { canReadCustomerData && <PlaceUserLinks /> }
        <Card className="col-span-2">
          <CellTable />
        </Card>
        <div className="grid gap-4">
          <Card>
            <Routes>
              <Route path="cells">
                <Route path=":serialNumber" element={<CellPanel />} />
              </Route>
            </Routes>
          </Card>
          <Card>
            <Routes>
              <Route path="cells">
                <Route
                  path=":serialNumber"
                  element={<SpaceMap linkBase={cellsHref} />}
                />
              </Route>
            </Routes>
          </Card>
        </div>
      </div>
    </PlaceDataProvider>
  );
};

export default Place;
