import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

import AuthProvider from "../AuthProvider";

function AdminArea() {
  const currentUser = useContext(AuthProvider.Context);
  const isAdmin = currentUser && currentUser.isAdmin;

  if (isAdmin) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Navigate to="/" replace={true} />
    </>
  );
}

export default AdminArea;
