import { Auth } from "aws-amplify";
import React, { Fragment, useContext } from "react";
import AuthProvider from "./AuthProvider";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import ThemePicker from "./ThemePicker";
import { UserCircleIcon } from "@heroicons/react/outline";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type MenuItemProps = {
  to: string;
  title: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const MenuItem = (props: MenuItemProps) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <Link
          to={props.to}
          onClick={props.onClick}
          className={classNames(
            active
              ? "bg-yellow-500 text-white"
              : "text-gray-700 dark:text-neutral-200",
            "block px-4 py-2 text-sm"
          )}
        >
          {props.title}
        </Link>
      )}
    </Menu.Item>
  );
};

const MenuItemGroup = (props: { children: React.ReactNode }) => {
  return <div className="py-2">{props.children}</div>;
};

const MenuBackground = (props: { children: React.ReactNode }) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease duration-100"
      enterFrom="transform opacity-0 scale-50"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease duration-100"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-50"
    >
      <Menu.Items className="absolute top-8 right-0 z-50 mt-2 w-56 origin-top-right rounded-xl bg-white shadow-xl ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white/[0.1] dark:bg-neutral-800 sm:top-auto sm:right-auto sm:left-0 sm:bottom-10 sm:mb-2 sm:origin-bottom-left sm:rounded-lg sm:shadow-lg sm:ring-1">
        {/* <Attachment className="hidden sm:block absolute h-3 -bottom-3 left-2 -scale-y-100 fill-white dark:fill-neutral-800 sm:stroke-black/[0.07] dark:sm:stroke-white/[0.1] sm:stroke-2"/> */}
        <div className="divide-y dark:divide-neutral-700">{props.children}</div>
      </Menu.Items>
    </Transition>
  );
};

const UserButton = () => {
  const currentUser = useContext(AuthProvider.Context);
  const isAdmin = currentUser && currentUser.isAdmin;

  const signOut = () => {
    Auth.signOut();
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="h-10 w-10 rounded-md p-2 text-stone-800 hover:bg-black/[0.05]  focus:outline-none active:hover:bg-black/[0.1] dark:text-neutral-400 dark:hover:bg-white/[0.05] dark:active:hover:bg-white/[0.1]">
        <UserCircleIcon className="h-full stroke-[1.5px]" />
      </Menu.Button>
      <MenuBackground>
        <div className="block px-4 py-2 text-sm font-medium">
          Signed in as {currentUser.email}
        </div>
        <div className="block px-4 py-2 text-sm font-medium">
          <ThemePicker />
        </div>
        <MenuItemGroup>
          {isAdmin && <MenuItem to="/admin/users" title="Admin" />}
          <MenuItem to="#" onClick={signOut} title="Sign Out" />
        </MenuItemGroup>
      </MenuBackground>
    </Menu>
  );
};

export default UserButton;
