import React, { useContext, useEffect, useReducer } from "react";
import SocketProvider, { useSocket } from "../socket/SocketProvider";
import SocketHandler, { TopicHandler } from "../socket/SocketHandler";

type State = {
  socket: SocketHandler | undefined;
  handlers: TopicHandler[];
};

type Action =
  | {
      type: "REGISTER";
      topic: string;
      event: string;
      callback: (object: any) => void;
    }
  | {
      type: "UNREGISTER";
      topic: string;
      event: string;
      callback: (object: any) => void;
    }
  | { type: "REPLACE_SOCKET"; socket: SocketHandler | undefined };

const registerCallback = (
  state: State,
  topic: string,
  event: string,
  callback: (object: any) => void
) => {
  const handlers = [...state.handlers];
  handlers.push({ topic, event, callback });

  if (state.socket) {
    state.socket.addTopicCallback(topic, event, callback);
  }

  return { socket: state.socket, handlers };
};

const unregisterCallback = (
  state: State,
  topic: string,
  event: string,
  callback: (object: any) => void
) => {
  const handlers = [
    ...state.handlers.filter(
      (handler) =>
        !(
          handler.topic === topic &&
          handler.event === event &&
          handler.callback === callback
        )
    ),
  ];

  if (state.socket) {
    state.socket.removeTopicCallback(topic, event, callback);
  }

  return { socket: state.socket, handlers };
};

const replaceSocket = (state: State, socket: SocketHandler | undefined) => {
  if (socket === state.socket) {
    return state;
  }

  if (state.socket) {
    state.socket.reset();
  }

  if (socket) {
    socket.reset();

    for (var handler of state.handlers) {
      socket.addTopicCallback(handler.topic, handler.event, handler.callback);
    }
  }

  return { socket, handlers: state.handlers };
};

const channelsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "REGISTER":
      return registerCallback(
        state,
        action.topic,
        action.event,
        action.callback
      );
    case "UNREGISTER":
      return unregisterCallback(
        state,
        action.topic,
        action.event,
        action.callback
      );
    case "REPLACE_SOCKET":
      return replaceSocket(state, action.socket);
    default:
      return state;
  }
};

const ChannelContext = React.createContext<React.Dispatch<Action>>(() => null);

const InnerChannelProvider = (props: { children: React.ReactNode }) => {
  const socket = useSocket();
  const [_state, dispatch] = useReducer(channelsReducer, {
    socket: undefined,
    handlers: [],
  });

  useEffect(() => {
    dispatch({ type: "REPLACE_SOCKET", socket: socket });
  }, [socket]);

  return (
    <ChannelContext.Provider value={dispatch}>
      {props.children}
    </ChannelContext.Provider>
  );
};

const ChannelProvider = (props: { children: React.ReactNode }) => {
  return (
    <SocketProvider>
      <InnerChannelProvider>{props.children}</InnerChannelProvider>
    </SocketProvider>
  );
};

const useTopicEvent = (
  topic: string,
  event: string,
  callback: (object: any) => void
) => {
  const dispatch = useContext(ChannelContext);

  useEffect(() => {
    dispatch({ type: "REGISTER", topic, event, callback });

    return () => {
      dispatch({ type: "UNREGISTER", topic, event, callback });
    };
  }, [dispatch, topic, event, callback]);
};

export default ChannelProvider;
export { useTopicEvent };
