import React, { useState } from "react";
import ControlSize from "../controls/ControlSize";
import Button from "../controls/Button";
import Checkbox from "../controls/Checkbox";
import LoadingContext from "../controls/LoadingContext";

type PartialState = {
  primary: boolean;
  disabled: boolean;
  description: string;
};

export default function Buttons() {
  const [isLoading, setIsLoading] = useState(false);
  const controlSizes: ControlSize[] = [
    ControlSize.Large,
    ControlSize.Medium,
    ControlSize.Small,
  ];
  const columns: PartialState[] = [
    { primary: false, disabled: false, description: "Base" },
    { primary: false, disabled: true, description: "Disabled" },
    { primary: true, disabled: false, description: "Primary" },
    { primary: true, disabled: true, description: "Primary Disabled" },
  ];

  return (
    <>
      <div className="min-w-full px-8">
        <LoadingContext.Provider value={isLoading}>
          <table className="table-fixed text-center">
            <thead>
              <tr>
                {columns.map((state) => (
                  <th key={state.description}>{state.description}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {controlSizes.map((size) => (
                <tr key={size}>
                  {columns.map((state) => (
                    <td key={state.description} className="w-1/12 py-2">
                      <Button
                        primary={state.primary}
                        disabled={state.disabled}
                        size={size}
                      >
                        Button
                      </Button>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </LoadingContext.Provider>
        <Checkbox
          title="Loading"
          id="loading"
          value={isLoading}
          onChange={setIsLoading}
        />
      </div>
    </>
  );
}
