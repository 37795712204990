import React, { useContext } from "react";
import {
  defaultLabelStyle,
  LabelState,
  LabelStyleFactory,
} from "./LabelStyleFactory";
import ControlSize from "./ControlSize";
import LoadingContext from "./LoadingContext";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type _LabelProps<E extends React.ElementType> = {
  as?: E;
  title: string;
  secondary: boolean;
  selectedBackground: boolean;
  disabled: boolean;
  mono: boolean;
  size: ControlSize;
  loading?: boolean;
  style: LabelStyleFactory;
  className?: string;
};

type PropsOf<E extends React.ElementType> = React.ComponentPropsWithRef<E>;
type Merge<T, U> = T & Omit<U, keyof T>;
type LabelProps<E extends React.ElementType = React.ElementType> = Merge<
  _LabelProps<E>,
  PropsOf<E>
>;

const defaultElement = "label";

function useLoading(override?: boolean): boolean {
  const isLoadingViaContext = useContext(LoadingContext);
  return override ?? isLoadingViaContext;
}

const _Label = (props: LabelProps, ref: React.Ref<Element>) => {
  const {
    as: requestedElement,
    title,
    secondary,
    selectedBackground,
    disabled,
    mono,
    size,
    loading,
    style,
    className,
    ...rest
  } = props;

  const isLoading = useLoading(loading);

  const state: LabelState = {
    secondary: secondary,
    selectedBackground: selectedBackground,
    disabled: disabled,
    mono: mono,
    size: size,
    loading: isLoading,
  };

  const ResolvedElement = requestedElement ?? defaultElement;
  const resolvedClassName = classNames(style(state), className ?? "");

  return (
    <ResolvedElement
      ref={ref}
      className={resolvedClassName}
      disabled={disabled}
      {...rest}
    >
      {props.title}
    </ResolvedElement>
  );
};

const Label = React.forwardRef(_Label);

Label.defaultProps = {
  secondary: false,
  selectedBackground: false,
  disabled: false,
  mono: false,
  size: ControlSize.Medium,
  loading: undefined,
  htmlFor: undefined,
  style: defaultLabelStyle,
};

export { defaultLabelStyle };
export default Label;
