import { API } from "aws-amplify";
import { useEffect } from "react";

const useAPIGet = (callback: (data: any) => void, path?: string) => {
  useEffect(() => {
    if (!path) {
      return;
    }

    const promise = API.get("dashboard", path, {});
    const fetchData = async () => {
      try {
        const result = await promise;
        callback(result);
      } catch (error) {
        if (API.isCancel(error)) {
          // cancelled because a newer search was started
        } else {
          console.log("Error: ", error);
        }
      }
    };

    fetchData();

    return () => {
      API.cancel(promise, "cancelled");
    };
  }, [callback, path]);
};

export { useAPIGet };
