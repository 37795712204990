import { Fragment, useCallback, useEffect, useState } from "react";
import { Routes, Route, useHref, useParams } from "react-router-dom";
import { useAPIGet } from "./data/useAPIGet";
import libphonenumber from "google-libphonenumber";
import PlaceDataProvider from "./data/PlaceDataProvider";

import CellPanel from "./place/CellPanel";
import CellTable from "./place/CellTable";
import DiagnosticsPanel from "./place/DiagnosticsPanel";
import SpaceMap from "./place/SpaceMap";

import Card from "./Card";
import ControlSize from "./controls/ControlSize";
import Label from "./controls/Label";
import LoadingContext from "./controls/LoadingContext";

type RowProps = {
  title: string;
  value?: string;
  mono: boolean;
};

const Row = (props: RowProps) => {
  const isMissing = props.value === undefined;
  return (
    <div className="flex justify-between py-3 text-sm font-medium">
      <dt>
        <Label secondary loading={undefined} title={props.title} />
      </dt>
      <dd className="text-right">
        <Label
          mono={props.mono}
          secondary={isMissing}
          disabled={isMissing}
          title={props.value ?? "—"}
        />
      </dd>
    </div>
  );
};

Row.defaultProps = {
  mono: false,
};

type User = {
  syng_id: string;
  phone_number: string;
  first_name?: string;
  last_name?: string;
  email?: string;
};

type Loadable<Value> = {
  isLoading: boolean;
  value: Value;
}

export function useUser(syngID: string): Loadable<User> {
  const [user, setUser] = useState<Loadable<User>>({isLoading: true, value: placeholderUserData});
  const callback = useCallback((result) => {
    setUser({isLoading: false, value: result});
  }, []);
  useEffect(() => {
    setUser({isLoading: true, value: placeholderUserData});
  }, [setUser, syngID]);
  useAPIGet(callback, `/users/${syngID}`);
  return user;
}

const placeholderUserData: User = {
  syng_id: "XXXXXXXX",
  phone_number: "+15558675309",
};

function formatPhoneNumber(phoneNumber: string): string {
  try {
    const util = libphonenumber.PhoneNumberUtil.getInstance();
    const parsed = util.parse(phoneNumber);
    const formatted = util.format(
      parsed,
      libphonenumber.PhoneNumberFormat.NATIONAL
    );
    return `+${parsed.getCountryCode()} ${formatted}`;
  } catch {
    // fall back to the unformatted number
    return phoneNumber;
  }
}

const UserPanel = (props: { user: User }) => {
  const { user } = props;
  return (
    <div className="bg-stone-50 px-4 py-2 dark:bg-neutral-800 sm:px-6">
      <dl className="divide-y divide-stone-200 dark:divide-neutral-700">
        <Row title="First Name" value={user.first_name} />
        <Row title="Last Name" value={user.last_name} />
        <Row
          title="Phone Number"
          value={formatPhoneNumber(user.phone_number)}
        />
        <Row title="Email" value={user.email} />
        <Row title="Syng ID" value={user.syng_id} mono />
      </dl>
    </div>
  );
};

export const UserHeader = ({user, selectedBackground, inline }: { user: User, selectedBackground?: boolean, inline?: boolean }) => {
  const nameParts = [user.first_name, user.last_name]
    .filter((s) => s !== undefined)
    .map((s) => s ?? "");
  const fullName = nameParts.join(" ");
  const phoneNumber = formatPhoneNumber(user.phone_number);

  var lines: string[] = [];
  if (fullName.length !== 0) {
    lines.push(fullName);
  }
  lines.push(phoneNumber);
  if (user.email) {
    lines.push(user.email);
  }

  const multiline = inline != true;

  return (
    <div className="flex flex-wrap gap-x-2">
      {lines.map((string, index) => (
        <Fragment key={index}>
          {index > 1 && <div className="opacity-25"><Label secondary={true} title="|" selectedBackground={selectedBackground ?? false} size={ControlSize.Medium} /></div>}
          <div className={index === 0 && multiline ? "basis-full" : ""}>
            <Label
              secondary={index !== 0}
              title={string}
              selectedBackground={selectedBackground ?? false}
              size={index === 0 ? ControlSize.Large : ControlSize.Medium}
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};

type PlaceOption = {
  name: string;
  placeID: string;
};

const User = () => {
  const { syngID } = useParams<{ syngID: string }>();
  const [user, setUser] = useState(placeholderUserData);
  const [placeOptions, setPlaceOptions] = useState<PlaceOption[]>([]);
  const [selectedPlaceOption, setSelectedPlaceOption] = useState<
    PlaceOption | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const cellsHref = useHref("cells/");

  // reset state when syngID changes
  useEffect(() => {
    setIsLoading(true);
    setUser(placeholderUserData);
    setPlaceOptions([]);
    setSelectedPlaceOption(undefined);
  }, [syngID]);

  const callback = useCallback(
    (object: any) => {
      setUser(object as User);
      setIsLoading(false);
    },
    [syngID]
  );

  const placesCallback = useCallback(
    (object: any) => {
      const response: {
        places: {
          name: string;
          place_id: string;
        }[];
      } = object;

      const options: PlaceOption[] = response.places.map((place) => {
        return {
          name: place.name,
          placeID: place.place_id,
        };
      });

      setPlaceOptions(options);
      if (options.length !== 0) {
        const first = options[0];
        setSelectedPlaceOption((existing) => existing ?? first);
      }
    },
    [syngID]
  );

  useAPIGet(callback, syngID ? `/users/${syngID}` : undefined);
  useAPIGet(placesCallback, syngID ? `/users/${syngID}/places` : undefined);

  return (
    <LoadingContext.Provider value={isLoading}>
      <div className="m-auto grid max-w-6xl grid-cols-3 gap-4 px-8 pb-8">
        <div className="col-span-3">
          <UserHeader user={user} />
        </div>
        <PlaceDataProvider
          placeID={selectedPlaceOption ? selectedPlaceOption.placeID : ""}
        >
          <div className="col-span-2 flex flex-col gap-4">
            <Card>
              <CellTable />
            </Card>
            <Card>
              <LoadingContext.Provider value={syngID === undefined}>
                <DiagnosticsPanel syngID={syngID ?? ""} />
              </LoadingContext.Provider>
            </Card>
          </div>
          <div className="flex flex-col gap-4">
            <Card>
              <Routes>
                <Route path="cells">
                  <Route path=":serialNumber" element={<CellPanel />} />
                </Route>
              </Routes>
            </Card>
            <Card>
              <Routes>
                <Route path="cells">
                  <Route
                    path=":serialNumber"
                    element={<SpaceMap linkBase={cellsHref} />}
                  />
                </Route>
              </Routes>
            </Card>
          </div>
        </PlaceDataProvider>
      </div>
    </LoadingContext.Provider>
  );
};

export default User;
export { formatPhoneNumber };
