import { Socket, Channel } from "phoenix";

type TopicHandler = {
  topic: string;
  event: string;
  callback: (object: any) => void;
};

interface ChannelMap {
  [index: string]: Channel;
}

interface ChannelEventMap {
  [index: string]: string[];
}

class SocketHandler {
  constructor(socket: Socket) {
    this.socket = socket;
  }

  addTopicCallback(
    topic: string,
    event: string,
    callback: (object: any) => void
  ) {
    const handler: TopicHandler = { topic, event, callback };
    this.topicHandlers.push(handler);
    this.ensureChannelEventHandler(topic, event);
  }

  removeTopicCallback(
    topic: string,
    event: string,
    callback: (object: any) => void
  ) {
    // FIXME: tear down the channel/event listener if necessary
    this.topicHandlers = [
      ...this.topicHandlers.filter(
        (handler) =>
          !(
            handler.topic === topic &&
            handler.event === event &&
            handler.callback === callback
          )
      ),
    ];
  }

  reset() {
    for (var topic in this.channels) {
      this.channels[topic].leave();
    }

    this.channels = {};
    this.channelEvents = {};
    this.topicHandlers = [];
  }

  private ensureChannelEventHandler(topic: string, event: string) {
    var channel: Channel;
    if (topic in this.channels) {
      channel = this.channels[topic];
    } else {
      channel = this.socket.channel(topic);
      channel.join();
      this.channels[topic] = channel;
    }

    var events: string[];
    if (topic in this.channelEvents) {
      events = this.channelEvents[topic];
    } else {
      events = [];
      this.channelEvents[topic] = events;
    }

    if (event in events) {
      return;
    }

    channel.on(event, (object) =>
      this.dispatchTopicEvent(topic, event, object)
    );
    events.push(event);
  }

  private dispatchTopicEvent(topic: string, event: string, object: any) {
    for (var handler of this.topicHandlers) {
      if (handler.topic === topic && handler.event === event) {
        handler.callback(object);
      }
    }
  }

  private socket: Socket;
  private channels: ChannelMap = {};
  private channelEvents: ChannelEventMap = {};
  private topicHandlers: TopicHandler[] = [];
}

export { type TopicHandler };
export default SocketHandler;
