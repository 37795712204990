import Card from "../Card";
import DiagnosticsPanel from "../place/DiagnosticsPanel";

const CannedDemo = () => {
  return (
    <div className="m-auto grid max-w-6xl grid-cols-3 gap-4 px-8 pb-8">
      <div className="col-span-3">
        <Card>
          <DiagnosticsPanel syngID="canned-demo-logs" />
        </Card>
      </div>
    </div>
    )
}

export default CannedDemo;
