import React, { useContext } from "react";
import {
  CheckboxState,
  CheckboxStyleFactory,
  defaultCheckboxStyle,
} from "./CheckboxStyleFactory";
import ControlSize from "./ControlSize";
import Label from "./Label";
import { defaultLabelStyle, LabelStyleFactory } from "./LabelStyleFactory";
import LoadingContext from "./LoadingContext";

type CheckboxProps = {
  title?: string;
  description?: string;
  id?: string;
  value: boolean;
  disabled: boolean;
  size: ControlSize;
  loading?: boolean;
  onChange?: (newValue: boolean) => void;
  style: CheckboxStyleFactory;
  labelStyle: LabelStyleFactory;
};

function descriptionID(id?: string): string | undefined {
  return id ? id + "-description" : id;
}

function labelOffset(size: ControlSize): string {
  switch (size) {
    case ControlSize.Small:
      return "ml-2 sm:ml-1 -mt-[0.2rem] sm:-mt-[0.25rem]";
    case ControlSize.Medium:
      return "ml-2 -mt-[0.2rem] sm:-mt-[0.225rem]";
    case ControlSize.Large:
      return "ml-2 sm:ml-1.5 -mt-1 sm:-mt-[0.175rem]";
  }
}

const Checkbox = (props: CheckboxProps) => {
  const handleClick: React.ChangeEventHandler<HTMLInputElement> = (_event) => {
    if (props.onChange) {
      props.onChange(!props.value);
    }
  };

  const isLoadingViaContext = useContext(LoadingContext);
  const isLoading = props.loading ?? isLoadingViaContext;

  const state: CheckboxState = {
    checked: props.value,
    disabled: props.disabled,
    size: props.size,
    loading: isLoading,
  };

  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={props.id}
          aria-describedby={descriptionID(props.id)}
          name={props.title}
          type="checkbox"
          disabled={isLoading || props.disabled}
          checked={props.value}
          className={props.style(state)}
          onChange={handleClick}
        />
      </div>
      <div className={labelOffset(props.size)}>
        {props.title && (
          <Label
            htmlFor={props.id}
            title={props.title}
            disabled={isLoading || props.disabled}
            size={props.size}
            loading={isLoading}
            style={props.labelStyle}
          />
        )}
        {props.description && (
          <p
            id={descriptionID(props.id)}
            className={props.labelStyle({
              secondary: true,
              selectedBackground: false,
              disabled: isLoading || props.disabled,
              mono: false,
              size: props.size,
              loading: isLoading,
            })}
          >
            {props.description}
          </p>
        )}
      </div>
    </div>
  );
};

Checkbox.defaultProps = {
  disabled: false,
  size: ControlSize.Medium,
  loading: null,
  title: null,
  description: null,
  id: null,
  style: defaultCheckboxStyle,
  labelStyle: defaultLabelStyle,
};

export { defaultCheckboxStyle };
export default Checkbox;
