import { Link } from "react-router-dom";
import { ReactComponent as SymIcon } from "../assets/svg/Sym.svg";
import SearchButton from "./search/SearchButton";
import UserButton from "./UserButton";
import EnvButton from "./status/EnvButton";

const Sidebar = () => {
  return (
    <div className="fixed inset-x-0 flex h-16 flex-row items-center border-b border-stone-200 bg-stone-100 p-4 dark:border-neutral-700 dark:bg-black sm:inset-x-auto sm:inset-y-0 sm:h-auto sm:w-16 sm:flex-col sm:border-b-0 sm:border-r dark:sm:bg-neutral-800">
      <Link
        to="/"
        className="h-10 rounded-md p-2 text-stone-800 hover:bg-black/[0.05]  active:hover:bg-black/[0.1] dark:text-neutral-400 dark:hover:bg-white/[0.05] dark:active:hover:bg-white/[0.1]"
      >
        <SymIcon className="h-full text-stone-800 dark:text-neutral-400" />
      </Link>
      <div className="flex-grow sm:hidden" />
      <SearchButton />
      <div className="flex-grow" />
      <UserButton />
      <EnvButton />
    </div>
  );
};

export default Sidebar;
