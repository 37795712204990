import { useContext } from "react";
import { RadioGroup } from "@headlessui/react";
import { ThemeContext, ThemePreference } from "./ThemeProvider";

const Option = (props: { value: ThemePreference; title: string }) => {
  return (
    <RadioGroup.Option
      className="ui-checked:font-black text-center cursor-pointer"
      value={props.value}
    >
      {props.title}
    </RadioGroup.Option>
  );
};

const ThemePicker = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  return (
    <RadioGroup value={theme} onChange={setTheme} className="flex">
      <RadioGroup.Label>Theme:</RadioGroup.Label>
      <div className="flex-grow grid grid-cols-3 mx-2 text-stone-700 dark:text-neutral-200">
        <Option value={ThemePreference.light} title="Light" />
        <Option value={ThemePreference.dark} title="Dark" />
        <Option value={ThemePreference.auto} title="Auto" />
      </div>
    </RadioGroup>
  );
};

export default ThemePicker;
