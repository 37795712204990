import { API } from "aws-amplify";
import React from "react";

import Button from "./controls/Button";

function Fetch() {
  const [fetchedData, setFetchedData] = React.useState("");

  const fetchSomething = () => {
    API.get("dashboard", "/users/e579c46e458b42f98d2f262ac1ecdaeb", {})
      .then((response) => {
        console.log("fetched:", response);
        setFetchedData(JSON.stringify(response, null, " "));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchAllUsers = () => {
    API.get("dashboard", "/all_users", {})
      .then((response) => {
        console.log("fetched:", response);
        setFetchedData(JSON.stringify(response, null, " "));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchNodeInfo = () => {
    API.get("dashboard", "/node_info", {})
      .then((response) => {
        console.log("fetched:", response);
        setFetchedData(JSON.stringify(response, null, " "));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const listRPC = () => {
    API.get("dashboard", "/rpc/list", {})
      .then((response) => {
        console.log("fetched:", response);
        setFetchedData(JSON.stringify(response, null, " "));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const sendRPC = () => {
    API.post("dashboard", "/rpc/send", {
      body: { cell: "6JEZFLXNLY5CV712S", message: "IdentifyCell" },
    })
      .then((response) => {
        console.log("fetched:", response);
        setFetchedData(JSON.stringify(response, null, " "));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const refreshRPC = () => {
    API.post("dashboard", "/rpc/refresh", {})
      .then((response) => {
        console.log("fetched:", response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const testIndexing = () => {
    API.get("dashboard", "/indexing", {})
      .then((response) => {
        console.log("fetched:", response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const reindexUsers = () => {
    API.post("dashboard", "/reindex_users", {})
      .then((response) => {
        console.log("fetched:", response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const buttons: [title: string, action: () => void][] = [
    ["Test Indexing", testIndexing],
    ["List RPC", listRPC],
    ["Send RPC", sendRPC],
    ["Refresh RPC", refreshRPC],
    ["Reindex Users", reindexUsers],
    ["Node Info", fetchNodeInfo],
    ["List Users", fetchAllUsers],
    ["Test Fetching", fetchSomething],
  ];

  return (
    <div className="flex h-full w-full justify-center">
      <div className="my-9 w-3/5">
        <div className="grid grid-cols-1 justify-items-stretch gap-3 pb-3 sm:grid-cols-2 md:grid-cols-3">
          {buttons.map((pair) => (
            <Button key={pair[0]} fullWidth capsule onClick={pair[1]}>
              {pair[0]}
            </Button>
          ))}
        </div>
        <div>
          <textarea
            value={fetchedData}
            className="mx-auto h-72 w-full whitespace-pre rounded-md bg-stone-800 font-mono text-xs text-slate-300 ring-1 ring-black/[0.1]"
            readOnly
          />
        </div>
      </div>
    </div>
  );
}

export default Fetch;
