import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../controls/Button";
import Card from "../Card";
import CloseButton from "../controls/CloseButton";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import DiagnosticSession from "../data/DiagnosticSession";

import { DownloadIcon } from "@heroicons/react/outline";
import { DeviceMobileIcon } from "@heroicons/react/solid";
import { ReactComponent as CellIcon } from "../../assets/svg/CellIcon.svg";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function formatDateString(dateString?: string): string | undefined {
  if (!dateString) {
    return undefined;
  }

  return String(new Date(dateString));
}

function formatFileSize(size: number): string {
  const units: {
    unit: "megabyte" | "kilobyte" | "byte";
    value: number;
  }[] = [
    { unit: "megabyte", value: 1000 * 1000 },
    { unit: "kilobyte", value: 1000 },
    { unit: "byte", value: 1 },
  ];

  for (const { unit, value } of units) {
    if (value < size) {
      return Intl.NumberFormat(undefined, {
        style: "unit",
        unit: unit,
        unitDisplay: "short",
        maximumFractionDigits: 1,
      }).format(size / value);
    }
  }

  return "0 B";
}

type Log = NonNullable<ReturnType<DiagnosticSession["logs"]["at"]>>;

const LogRow = (props: { log: Log }) => {
  const { type, from, url, size } = props.log;

  const Icon = type === "mobile" ? DeviceMobileIcon : CellIcon;

  return (
    <a href={url}>
      <div className="flex flex-row items-center justify-between gap-2">
        <Icon className="h-8 w-8 flex-none opacity-50" />
        <div className="pointer-events-none flex flex-col">
          <Label title={from} mono />
          <Label title={formatFileSize(size)} secondary />
        </div>
        <span className="flex-grow" />
        <DownloadIcon className="h-5 w-5 flex-none" />
      </div>
    </a>
  );
};

const Interior = (props: {
  sessionID?: string;
  sessions: DiagnosticSession[];
  onClose?: () => void;
}) => {
  const session = useMemo((): DiagnosticSession | undefined => {
    if (!props.sessions || !props.sessionID) {
      return undefined;
    }

    return props.sessions.find(
      (session) => session.sessionID === props.sessionID
    );
  }, [props.sessionID, props.sessions]);

  const downloadAll = useCallback(() => {
    if (session && session.archive) {
      window.location.assign(session.archive.url);
    }
  }, [session]);

  var sortedLogs: Log[];
  if (session) {
    sortedLogs = session.logs;
    sortedLogs.sort((lhs, rhs) => {
      if (lhs.type === rhs.type) {
        if (lhs.from === rhs.from) {
          return 0;
        }

        return lhs.from < rhs.from ? -1 : 1;
      }

      return lhs.type < rhs.type ? -1 : 1;
    });
  } else {
    sortedLogs = [];
  }

  return (
    <>
      <div className="flex items-start justify-between px-3 py-3">
        <div className="flex flex-col gap-1">
          <Label title="Diagnostic Session" size={ControlSize.Large} />
          <Label
            secondary
            title={formatDateString(session?.date) ?? ""}
            size={ControlSize.Medium}
          />
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="flex flex-col gap-4 bg-stone-50 px-3 py-5 dark:bg-neutral-800">
        {sortedLogs.map((log) => (
          <LogRow key={log.from} log={log} />
        ))}
      </div>
      <div className="grid gap-4 px-4 py-5 sm:flex sm:flex-row-reverse sm:px-6">
        <Button
          primary
          fullWidth
          disabled={!(session && session.archive)}
          onClick={downloadAll}
        >
          <span className="flex flex-row items-center gap-1">
            Download All
            {session && session.archive && (
              <span className="">({formatFileSize(session.archive.size)})</span>
            )}
            <DownloadIcon className="h-5 w-5" />
          </span>
        </Button>
        <Button fullWidth onClick={props.onClose}>
          Close
        </Button>
      </div>
    </>
  );
};

const DiagnosticSessionDialog = (props: { sessions: DiagnosticSession[] }) => {
  const query = useQuery();
  const sessionID = query.get("diagnosticSessionID") ?? undefined;
  const navigate = useNavigate();
  const closeDialog = useCallback(() => {
    query.delete("diagnosticSessionID");
    navigate(`?${query.toString()}`);
  }, [navigate, query]);

  // Latch the session ID, otherwise the data will disappear when the dialog closes.
  const [latchedSessionID, setLatchedSessionID] =
    useState<string | undefined>(undefined);
  useEffect(() => {
    if (sessionID) {
      setLatchedSessionID(sessionID);
    }
  }, [sessionID]);

  return (
    <Transition show={sessionID !== undefined} as={Fragment}>
      <Dialog onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black/40 dark:bg-black/60"
            aria-hidden="true"
          />
        </Transition.Child>

        <div className="fixed inset-0 flex flex-col items-center justify-end py-4 sm:justify-center sm:p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="mx-auto h-fit w-full sm:max-w-lg">
              <Card className="divide-y divide-stone-100 overflow-hidden bg-white dark:divide-neutral-600 dark:bg-neutral-700">
                <Interior
                  sessionID={latchedSessionID}
                  sessions={props.sessions}
                  onClose={closeDialog}
                />
              </Card>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DiagnosticSessionDialog;
