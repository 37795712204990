import version from "../../version.json";

type BuildVersion = {
  type: "build";
  commitHash: string;
  commitShortHash: string;
};

type LocalVersion = {
  type: "local";
};

type DashboardVersion = LocalVersion | BuildVersion;

const currentVersion = version as DashboardVersion;

export { currentVersion, type DashboardVersion };
