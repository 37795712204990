import { API } from "aws-amplify";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Button from "../controls/Button";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import LoadingContext from "../controls/LoadingContext";
import EditUserPanel from "./EditUserPanel";

type User = {
  id: string;
  email: string;
};

const placeholderUsers: User[] = [
  { id: "91136908-BE98-4CC9-91D6-4056C5C39E7C", email: "foo@bar.com" },
  { id: "6792AC48-74A1-4142-A48A-B0F994B71852", email: "jappleseed@apple.com" },
  { id: "5CF1A718-88F9-4894-9B1E-3C08AC1AE8A0", email: "bar@example.com" },
  { id: "2C59710B-59A4-40BF-BD75-BABEA4C98FEE", email: "foo@bar.com" },
];

type UserRowProps = {
  user: User;
  onEdit?: (user: User) => void;
};

function UserRow(props: UserRowProps) {
  const onClick = () => {
    if (props.onEdit) {
      props.onEdit(props.user);
    }
  };

  return (
    <div className="flex items-baseline justify-between py-3 px-4 text-sm font-medium sm:px-6">
      <dt>
        <Label title={props.user.email} />
      </dt>
      <dd>
        <Button onClick={onClick}>Edit</Button>
      </dd>
    </div>
  );
}

function Users() {
  const [once] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>(placeholderUsers);
  const [isEditing, setIsEditing] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await API.get("dashboard", "/admin/users", {});
      setUsers(result);
      setIsLoading(false);
    };

    fetchData();
  }, [once]);

  const editUser = (user: User) => {
    setEditingUser(user);
    setIsEditing(true);
  };

  return (
    <>
      <aside className="m-auto mt-8 w-96 divide-y divide-stone-100 overflow-hidden rounded-2xl bg-white shadow dark:divide-neutral-600 dark:border dark:border-white/[0.01] dark:bg-neutral-700 dark:shadow-none sm:w-1/2 sm:rounded-xl dark:sm:shadow-[0_10px_15px_-3px_rgba(0,0,0,0.25)]">
        <div className="px-4 py-5 sm:px-6">
          <Label title="Users" size={ControlSize.Large} />
        </div>
        <div className="bg-stone-50 py-2 dark:bg-neutral-800">
          <LoadingContext.Provider value={isLoading}>
            <dl className="divide-y divide-stone-200 dark:divide-neutral-700">
              {users.map((user) => (
                <div key={user.id}>
                  <UserRow user={user} onEdit={editUser} />
                </div>
              ))}
            </dl>
          </LoadingContext.Provider>
        </div>
      </aside>
      <Transition show={isEditing} as={Fragment}>
        <Dialog onClose={() => setIsEditing(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/40 dark:bg-black/60"
              aria-hidden="true"
            />
          </Transition.Child>
          <div className="fixed inset-0 flex flex-col items-center justify-end py-4 sm:justify-center sm:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md">
                {editingUser && (
                  <EditUserPanel
                    user={editingUser}
                    onClose={() => setIsEditing(false)}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default Users;
