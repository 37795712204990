import { useState } from "react";
import { RadioGroup } from "@headlessui/react";

const colors = [
  "bg-black",
  "bg-stone-900 dark:bg-neutral-900",
  "bg-stone-800 dark:bg-neutral-800",
  "bg-stone-700 dark:bg-neutral-700",
  "bg-stone-600 dark:bg-neutral-600",
  "bg-stone-500 dark:bg-neutral-500",
  "bg-stone-400 dark:bg-neutral-400",
  "bg-stone-300 dark:bg-neutral-300",
  "bg-stone-200 dark:bg-neutral-200",
  "bg-stone-100 dark:bg-neutral-100",
  "bg-white",
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type BackgroundColorPickerProps = {
  backgroundColor: string;
  onChange: (newColor: string) => void;
};

const BackgroundColorPicker = (props: BackgroundColorPickerProps) => {
  const [selectedColor, setSelectedColor] = useState(colors[1]);

  const selectionChanged = (newColor: string) => {
    setSelectedColor(newColor);
    props.onChange(newColor);
  };

  return (
    <RadioGroup value={selectedColor} onChange={selectionChanged}>
      <div className="flex items-center space-x-3">
        {colors.map((color) => (
          <RadioGroup.Option
            key={color}
            value={color}
            className="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-offset-transparent focus:outline-none"
          >
            {({ checked }) => (
              <div
                aria-hidden="true"
                className={classNames(
                  color,
                  "flex h-6 w-6 items-center justify-center rounded-full border border-black border-opacity-10 ring-1 ring-white/[0.15]"
                )}
              >
                {checked && (
                  <div className="h-3 w-3 rounded-full bg-neutral-500" />
                )}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default BackgroundColorPicker;
