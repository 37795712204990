import { Fragment, useCallback, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Card from "../Card";
import CloseButton from "../controls/CloseButton";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { currentVersion } from "./DashboardVersion";

import config from "../../config.json";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function useQueryDialog(key: string): [boolean, () => void] {
  const query = useQuery();
  const navigate = useNavigate();
  const closeDialog = useCallback(() => {
    query.delete(key);
    navigate(`?${query.toString()}`);
  }, [navigate, query, key]);
  const isOpen = query.has(key);
  return [isOpen, closeDialog];
}

const EnvironmentLink = (props: { env: string }) => {
  const environments: { [key: string]: string | undefined } = {
    dev: "https://dashboard.dev.syng.tools",
    staging: "https://dashboard.staging.syng.tools",
    prod: "https://dashboard.syng.tools",
  };
  const baseUrl = environments[props.env] ?? "";
  const location = useLocation();
  const url = `${baseUrl}${location.pathname}${location.search}`;
  const isActive = props.env === config.deployment_name;

  console.log("Location: ", location);

  return (
    <a href={url}>
      <div className="flex flex-col items-center gap-2">
        <div
          className={classNames(
            "aspect-square w-20 rounded-xl bg-stone-200 dark:bg-neutral-600",
            isActive
              ? "ring-2 ring-yellow-400 ring-offset-2 ring-offset-stone-100 dark:ring-yellow-500 dark:ring-offset-neutral-800"
              : ""
          )}
        />
        <Label title={props.env} />
      </div>
    </a>
  );
};

const ExternalLink = (props: { to: string; children?: React.ReactNode }) => {
  return (
    <a
      href={props.to}
      target="blank"
      className="flex flex-row gap-[0.15rem] items-center text-blue-500"
    >
      {props.children}
      <ExternalLinkIcon className="w-4 stroke-[1.7pt]" />
    </a>
  );
};

const VersionIndicator = () => {
  if (currentVersion.type === "local") {
    return <div className="font-mono">local</div>;
  }

  return (
    <div className="font-mono">
      <ExternalLink
        to={`https://gitlab.com/syngoutloud/cloud-services/dashboard/-/tree/${currentVersion.commitHash}`}
      >
        {currentVersion.commitShortHash}
      </ExternalLink>
    </div>
  );
};

const Interior = (props: { onClose: () => void }) => {
  return (
    <>
      <div className="flex items-start justify-between px-3 py-3">
        <Label title="Environment" size={ControlSize.Large} />
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="flex flex-col gap-4 bg-stone-50 px-3 py-5 dark:bg-neutral-800">
        <div className="flex flex-row justify-around">
          <EnvironmentLink env="dev" />
          <EnvironmentLink env="staging" />
          <EnvironmentLink env="prod" />
        </div>
      </div>
      <div className="flex flex-row gap-4 justify-between bg-stone-50 px-3 py-3 text-sm dark:bg-neutral-800">
        <div className="flex gap-1 items-baseline">
          Dashboard Version: <VersionIndicator />
        </div>
        <ExternalLink to="/status">System Status</ExternalLink>
      </div>
    </>
  );
};

const EnvDialog = () => {
  const [isOpen, closeDialog] = useQueryDialog("envDialog");

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black/40 dark:bg-black/60"
            aria-hidden="true"
          />
        </Transition.Child>

        <div className="fixed inset-0 flex flex-col items-center justify-end py-4 sm:justify-start sm:p-4 sm:pt-32">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="mx-auto h-fit w-full sm:max-w-lg">
              <Card className="divide-y divide-stone-100 overflow-hidden bg-white dark:divide-neutral-600 dark:bg-neutral-700">
                <Interior onClose={closeDialog} />
              </Card>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EnvDialog;
