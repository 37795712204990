import React, { useState } from "react";

import ControlSize from "../controls/ControlSize";
import Button from "../controls/Button";
import Checkbox from "../controls/Checkbox";
import Label from "../controls/Label";
import LoadingContext from "../controls/LoadingContext";
import Switch from "../controls/Switch";

import BackgroundColorPicker from "./BackgroundColorPicker";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type ButtonPartialState = {
  primary: boolean;
  disabled: boolean;
};

const buttonPartials: ButtonPartialState[] = [
  { primary: false, disabled: false },
  { primary: false, disabled: true },
  { primary: true, disabled: false },
  { primary: true, disabled: true },
];

type CheckboxPartialState = {
  value: boolean;
  disabled: boolean;
};

const checkboxPartials: CheckboxPartialState[] = [
  { value: false, disabled: false },
  { value: false, disabled: true },
  { value: true, disabled: false },
  { value: true, disabled: true },
];

type SwitchPartial = {
  value: boolean;
  disabled: boolean;
};

const switchPartials: SwitchPartial[] = [
  { value: false, disabled: false },
  { value: false, disabled: true },
  { value: true, disabled: false },
  { value: true, disabled: true },
];

type LabelPartialState = {
  secondary: boolean;
  disabled: boolean;
};

const labelPartials: LabelPartialState[] = [
  { secondary: false, disabled: false },
  { secondary: false, disabled: true },
  { secondary: true, disabled: false },
  { secondary: true, disabled: true },
];

const controlSizes: ControlSize[] = [
  ControlSize.Large,
  ControlSize.Medium,
  ControlSize.Small,
];

function isEven(x: number): boolean {
  return x % 2 === 0;
}

interface ControlGridProps<P> {
  title: string;
  partials: P[];
  buildControl: (partial: P, size: ControlSize) => JSX.Element;
}

function ControlGrid<P>({
  title,
  partials,
  buildControl,
}: ControlGridProps<P>) {
  return (
    <>
      <div className="grid w-full grid-cols-2 gap-x-2 gap-y-6 px-8 py-4 sm:grid-cols-3 md:grid-cols-5">
        <div className="sticky top-14 text-xl font-bold uppercase text-stone-300 dark:text-neutral-700">
          {title}
        </div>
        {partials.map((state, index) => (
          <div
            key={index}
            className={classNames(
              "col-start-2 md:col-start-auto",
              isEven(index) ? "sm:col-start-2" : "sm:col-start-3"
            )}
          >
            {controlSizes.map((size) => (
              <div key={size} className="pt-1 pb-3">
                {buildControl(state, size)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default function Controls() {
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("dark:bg-neutral-900");

  function onChange(_value: boolean) {}

  function buildButton(partial: ButtonPartialState, size: ControlSize) {
    return (
      <Button {...partial} size={size}>
        Button
      </Button>
    );
  }

  function buildCapsuleButton(partial: ButtonPartialState, size: ControlSize) {
    return (
      <Button {...partial} capsule size={size}>
        Button
      </Button>
    );
  }

  function buildCheckbox(partial: CheckboxPartialState, size: ControlSize) {
    return (
      <Checkbox {...partial} size={size} title="Checkbox" onChange={onChange} />
    );
  }

  function buildSwitch(partial: SwitchPartial, size: ControlSize) {
    return (
      <Switch {...partial} size={size} title="Switch" onChange={onChange} />
    );
  }

  function buildLabel(partial: LabelPartialState, size: ControlSize) {
    return <Label {...partial} size={size} title="Label" />;
  }

  function buildSelectedLabel(partial: LabelPartialState, size: ControlSize) {
    return <Label {...partial} size={size} title="Label" selectedBackground />;
  }

  function bannerColor(_bg: string): string {
    return "bg-white dark:bg-black";
  }

  console.log(backgroundColor, bannerColor(backgroundColor));

  return (
    <div className={classNames("min-h-full w-full pt-12", backgroundColor)}>
      <div
        className={classNames(
          "fixed top-0 z-10 flex h-12 w-full items-center justify-between border-b px-8 dark:border-neutral-700/[.5]",
          bannerColor(backgroundColor)
        )}
      >
        <div className="text-xl font-bold uppercase dark:text-neutral-100">
          Controls
        </div>
        <BackgroundColorPicker
          backgroundColor={backgroundColor}
          onChange={setBackgroundColor}
        />
        <Switch
          title="Loading"
          labelPosition="leading"
          size={ControlSize.Medium}
          value={isLoading}
          onChange={setIsLoading}
        />
      </div>
      <LoadingContext.Provider value={isLoading}>
        <ControlGrid
          title="Button"
          partials={buttonPartials}
          buildControl={buildButton}
        />
        <ControlGrid
          title="Capsule Button"
          partials={buttonPartials}
          buildControl={buildCapsuleButton}
        />
        <ControlGrid
          title="Checkbox"
          partials={checkboxPartials}
          buildControl={buildCheckbox}
        />
        <ControlGrid
          title="Switch"
          partials={switchPartials}
          buildControl={buildSwitch}
        />
        <ControlGrid
          title="Label"
          partials={labelPartials}
          buildControl={buildLabel}
        />

        <div className="bg-yellow-500">
          <ControlGrid
            title="Selected Label"
            partials={labelPartials}
            buildControl={buildSelectedLabel}
          />
        </div>
      </LoadingContext.Provider>
    </div>
  );
}
