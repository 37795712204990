import { NavLink } from "react-router-dom";
import Label from "../controls/Label";
import WiFiIndicator from "../controls/WiFiIndicator";
import Cell from "../data/Cell";
import { LockClosedIcon } from "@heroicons/react/solid";

import { OTA, OTAIndicator } from "./CellPanel";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function formatWiFiChannel(channel?: number): string | undefined {
  if (!channel) {
    return undefined;
  }

  if (channel <= 14) {
    return "2.4 GHz";
  }

  return "5 GHz";
}

function otaInProgress(ota?: OTA): boolean {
  if (!ota) {
    return false;
  }

  switch (ota.status) {
    case "downloading":
    case "installing":
    case "rebooting":
      return true;
    default:
      return false;
  }
}

const RoleIndicator = (props: {
  role: string;
  isPinned: Boolean;
  selectedBackground: boolean;
}) => {
  return (
    <span
      className="flex flex-row items-baseline justify-start gap-1"
      title={props.isPinned ? "Role is pinned" : undefined}
    >
      {props.isPinned && (
        <LockClosedIcon
          className={classNames(
            "w-4 self-center",
            props.selectedBackground ? "text-black dark:text-white" : ""
          )}
        />
      )}
      <Label title={props.role} selectedBackground={props.selectedBackground} />
    </span>
  );
};

const CellRow = ({ cell }: { cell: Cell }) => {
  const hasOTA = otaInProgress(cell.ota);
  return (
    <NavLink to={"cells/" + cell.serialNumber}>
      {({ isActive }) => (
        <div
          className={classNames(
            "grid grid-cols-12 items-baseline text-left text-sm",
            isActive ? "bg-stone-200 dark:bg-neutral-600" : ""
          )}
        >
          <span className="m-auto">
            <WiFiIndicator
              rssi={cell.rssi ?? 0}
              channel={cell.wifiChannel ?? 0}
              isOnline={cell.isOnline}
            />
          </span>
          <span className="col-span-4 p-1 font-mono font-medium">
            <Label title={cell.serialNumber} selectedBackground={isActive} />
          </span>
          <span className="col-span-3">
            <RoleIndicator
              role={cell.role}
              isPinned={cell.rolePinned}
              selectedBackground={isActive}
            />
          </span>
          {!hasOTA && (
            <span>
              <Label
                title={formatWiFiChannel(cell.wifiChannel) ?? ""}
                selectedBackground={isActive}
              />
            </span>
          )}
          {cell.ota && hasOTA && (
            <span className="col-span-3">
              <OTAIndicator ota={cell.ota} isOnline={cell.isOnline} />
            </span>
          )}
        </div>
      )}
    </NavLink>
  );
};

export default CellRow;
