import ControlSize from "./ControlSize";

type LabelState = {
  secondary: boolean;
  selectedBackground: boolean;
  disabled: boolean;
  mono: boolean;
  size: ControlSize;
  loading: boolean;
};

type LabelStyleFactory = (state: LabelState) => string;

const defaultLabelStyle: LabelStyleFactory = (state) => {
  var styles: string[] = [];

  if (!state.secondary) {
    styles.push("font-medium");
  }

  if (state.mono) {
    styles.push("font-mono");
  }

  switch (state.size) {
    case ControlSize.Small:
      styles.push("text-base sm:text-xs");
      break;
    case ControlSize.Medium:
      styles.push("text-lg sm:text-sm");
      break;
    case ControlSize.Large:
      styles.push("text-xl sm:text-base");
      break;
  }

  if (state.loading) {
    styles.push("animate-pulse rounded-lg");

    if (state.secondary) {
      styles.push("text-transparent bg-stone-200");
      styles.push("dark:bg-neutral-200/[0.15]");
    } else {
      styles.push("text-transparent bg-stone-200");
      styles.push("dark:bg-neutral-200/[0.25]");
    }
  } else {
    if (state.disabled) {
      styles.push("opacity-50");
    }

    if (state.secondary) {
      if (state.selectedBackground) {
        styles.push("text-black/75 dark:text-white/75");
      } else {
        styles.push("text-stone-500");
        styles.push("dark:text-neutral-500");
      }
    } else {
      if (state.selectedBackground) {
        styles.push("text-black dark:text-white");
      } else {
        styles.push("text-stone-700");
        styles.push("dark:text-neutral-200");
      }
    }
  }

  return styles.join(" ");
};

export type { LabelState, LabelStyleFactory };
export { defaultLabelStyle };
