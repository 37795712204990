import { Link } from "react-router-dom";
import Label from "../controls/Label";
import DiagnosticSession from "../data/DiagnosticSession";

import { DeviceMobileIcon, EyeIcon } from "@heroicons/react/solid";
import { DownloadIcon } from "@heroicons/react/outline";
import { ReactComponent as CellIcon } from "../../assets/svg/CellIcon.svg";

function formatRelativeDate(date?: Date): string | undefined {
  if (!date) {
    return undefined;
  }

  const elapsed = date.valueOf() - Date.now();
  const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const units: { [P in Intl.RelativeTimeFormatUnit]?: number } = {
    year: 1000 * 60 * 60 * 24 * 365,
    month: (1000 * 60 * 60 * 24 * 365) / 12,
    day: 1000 * 60 * 60 * 24,
    hour: 1000 * 60 * 60,
    minute: 1000 * 60,
  };

  var name: Intl.RelativeTimeFormatUnit;
  for (name in units) {
    const value = units[name];
    if (value && Math.abs(elapsed) > value) {
      return formatter.format(Math.round(elapsed / value), name);
    }
  }

  return formatter.format(Math.round(elapsed / 1000), "second");
}

function formatDateString(dateString: string): string {
  const date = new Date(dateString);
  return formatRelativeDate(date) ?? String(date);
}

const Row = ({ session }: { session: DiagnosticSession }) => {
  const hasArchive = session.archive !== null;
  const mobileCount = session.logs.filter(
    (log) => log.type === "mobile"
  ).length;
  const cellLogs = session.logs.filter((log) => log.type === "cell");
  const cellCount = cellLogs.length;

  return (
    <div className="flex items-center justify-between gap-2">
      {cellCount === 1 && (
        <Label title={cellLogs[0].from} mono={true}/>
      )}
      <Label title={formatDateString(session.date)} secondary={cellCount === 1} />
      <div className="flex-grow" />
      {mobileCount !== 0 && (
        <div className="flex items-center opacity-30">
          <DeviceMobileIcon className="h-5 w-5" />
          <Label secondary title={`${mobileCount}`} />
        </div>
      )}
      {cellCount !== 0 && (
        <div className="flex items-center gap-1 opacity-30">
          <CellIcon className="h-4 w-4" />
          <Label secondary title={`${cellCount}`} />
        </div>
      )}
      <Link to={`?diagnosticSessionID=${session.sessionID}`}>
        <EyeIcon className="m-1 h-5 w-5" />
      </Link>
      <a
        href={session.archive?.url ?? "#"}
        className={hasArchive ? "" : "pointer-events-none opacity-30"}
      >
        <DownloadIcon className="m-1 h-5 w-5" />
      </a>
    </div>
  );
};

const DiagnosticSessionTable = (props: { sessions: DiagnosticSession[] }) => {
  var sessions = [...props.sessions];
  sessions.sort((lhs, rhs) => {
    if (lhs.date === rhs.date) {
      return 0;
    }

    return lhs.date < rhs.date ? 1 : -1;
  });

  return (
    <div className="flex flex-col gap-2">
      {sessions.map((session) => (
        <Row key={session.sessionID} session={session} />
      ))}
    </div>
  );
};
export default DiagnosticSessionTable;
