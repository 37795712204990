import { API } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import AuthProvider from "../AuthProvider";
import config from "../../config.json";

import Button from "../controls/Button";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import LoadingContext from "../controls/LoadingContext";
import Switch from "../controls/Switch";

type User = {
  id: string;
  email: string;
};

type EditUserPanelProps = {
  user: User;
  onClose: () => void;
};

function compareGroups(lhs: string[], rhs: string[]): boolean {
  if (lhs === rhs) {
    return true;
  }
  if (lhs.length !== rhs.length) {
    return false;
  }

  const length = lhs.length;
  for (var i = 0; i < length; i++) {
    if (lhs[i] !== rhs[i]) {
      return false;
    }
  }

  return true;
}

function EditUserPanel(props: EditUserPanelProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [originalGroups, setOriginalGroups] = useState<string[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const currentUser = useContext(AuthProvider.Context);

  const isCurrentUser = currentUser && props.user.email === currentUser.email;
  const hasChanges = !compareGroups(groups, originalGroups);

  useEffect(() => {
    const fetchData = async () => {
      const result = await API.get(
        "dashboard",
        `/admin/users/${props.user.id}/groups`,
        {}
      );

      const uniqueGroups: Set<string> = new Set(result);
      const sorted = Array.from(uniqueGroups);
      sorted.sort();

      setGroups(sorted);
      setOriginalGroups(sorted);
      setIsLoading(false);
    };

    fetchData();
  }, [props.user.id]);

  const applyChanges = async () => {
    setIsSaving(true);

    try {
      const result = await API.put(
        "dashboard",
        `/admin/users/${props.user.id}/groups`,
        {
          body: groups,
        }
      );
      console.log("Result: ", result);
      props.onClose();
    } catch (e) {
      console.log("Error updating groups:", e);
      setIsSaving(false);
    }
  };

  const modifyGroup = (groupName: string, isMember: boolean) => {
    let newGroups = new Set(groups);

    if (isMember) {
      newGroups.add(groupName);
    } else {
      newGroups.delete(groupName);
    }

    const sorted = Array.from(newGroups);
    sorted.sort();

    setGroups(sorted);
  };

  return (
    <>
      <aside className="w-auto divide-y divide-stone-100 overflow-hidden rounded-2xl bg-white dark:divide-neutral-600 dark:border dark:border-white/[0.01] dark:bg-neutral-700 dark:shadow-none sm:rounded-xl dark:sm:shadow-[0_10px_15px_-3px_rgba(0,0,0,0.5)]">
        <div className="px-4 py-5 sm:px-6">
          <Label size={ControlSize.Large} title={props.user.email} />
        </div>
        <div className="bg-stone-50 px-4 py-5 dark:bg-neutral-800 sm:px-6">
          <LoadingContext.Provider value={isLoading}>
            <fieldset className="space-y-5">
              {Object.entries(config.user_groups).map(([k, v]) => (
                <Switch
                  key={k}
                  fullWidth
                  labelPosition="leading"
                  title={k}
                  description={v}
                  value={groups.includes(k)}
                  onChange={(newValue) => modifyGroup(k, newValue)}
                  disabled={
                    isLoading || isSaving || (k === "admin" && isCurrentUser)
                  }
                />
              ))}
            </fieldset>
          </LoadingContext.Provider>
        </div>
        <div className="grid gap-4 px-4 py-5 sm:flex sm:flex-row-reverse sm:px-6">
          <Button
            primary
            fullWidth
            disabled={!hasChanges || isLoading || isSaving}
            onClick={applyChanges}
          >
            Save
          </Button>
          <Button fullWidth onClick={props.onClose}>
            Cancel
          </Button>
        </div>
      </aside>
    </>
  );
}

export default EditUserPanel;
