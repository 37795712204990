import { useCallback, useState } from "react";
import { Listbox } from "@headlessui/react";
import { API } from "aws-amplify";
import Button from "../controls/Button";
import {
  ButtonState,
  defaultButtonStyle,
} from "../controls/ButtonStyleFactory";
import Card from "../Card";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import { defaultLabelStyle } from "../controls/Label";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "../../utils/classNames";
import { useAPIGet } from "../data/useAPIGet";
import useQuery from "../../utils/useQuery";

const SendRPC = () => {
  const query = useQuery();
  const [serialNumber, setSerialNumber] = useState(
    query.get("serialNumber") ?? ""
  );
  const [selectedCommand, setSelectedCommand] =
    useState<string | undefined>(undefined);
  const [commands, setCommands] = useState<string[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [log, setLog] = useState("");

  useAPIGet((commands: string[]) => {
    setCommands(commands);

    if (commands.length !== 0 && !selectedCommand) {
      const first = commands[0];
      setSelectedCommand(first);
    }
  }, "/rpc/list");

  const serialNumberChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSerialNumber(event.currentTarget.value);
    },
    [setSerialNumber]
  );

  const appendToLog = useCallback(
    (line: string) => {
      setLog((log) => {
        if (log === "") {
          return line;
        }

        return log + "\n" + line;
      });
    },
    [setLog]
  );

  const sendMessage = useCallback(async () => {
    setIsSending(true);
    appendToLog(`Sending ${selectedCommand} to ${serialNumber}`);

    try {
      const body = {
        cell: serialNumber,
        message: selectedCommand,
      };
      const response = await API.post("dashboard", "/rpc/send", { body });
      appendToLog(JSON.stringify(response));
    } catch (error) {
      if (error instanceof Error) {
        appendToLog(error.toString());
      }
    }

    setIsSending(false);
  }, [appendToLog, selectedCommand, serialNumber]);

  const buttonState: ButtonState = {
    primary: false,
    disabled: false,
    size: ControlSize.Medium,
    fullWidth: false,
    capsule: false,
    loading: false,
  };

  const partialLabelState = {
    secondary: false,
    disabled: false,
    mono: false,
    size: ControlSize.Medium,
    loading: false,
  };

  const canSend = !isSending && serialNumber !== "" && selectedCommand;

  return (
    <Card className="mx-auto w-96 divide-y divide-stone-100 bg-white dark:divide-neutral-600 dark:bg-neutral-700 sm:w-1/2">
      <div className="flex items-start justify-between px-3 py-3">
        <Label title="Cell Remote" size={ControlSize.Large} />
      </div>
      <div className="flex flex-row flex-wrap gap-2 bg-stone-50 p-2 dark:bg-neutral-800">
        <input
          placeholder="Serial Number"
          value={serialNumber}
          onChange={serialNumberChanged}
          className="flex-grow rounded-lg bg-white px-6 py-2 !pl-3 !pr-1 text-lg font-medium shadow ring-1 ring-black/[0.08] focus:outline-none disabled:opacity-50 dark:bg-neutral-500/[0.5] dark:text-neutral-200 dark:ring-black/[0.18] dark:disabled:opacity-30 sm:rounded-md sm:px-4 sm:py-1.5 sm:text-sm"
        />
        <Listbox value={selectedCommand} onChange={setSelectedCommand}>
          <div className="relative w-36 flex-none">
            <Listbox.Button
              className={classNames(
                defaultButtonStyle(buttonState),
                "!w-full !pl-3 !pr-1"
              )}
            >
              <div className="flex w-full flex-row items-center justify-between">
                {selectedCommand ?? ""}
                <SelectorIcon className="w-5 stroke-[1.5pt]" />
              </div>
            </Listbox.Button>
            <Listbox.Options className="absolute -top-[0.875rem] -left-4 z-10  mt-1 rounded-lg bg-white py-3 shadow-lg ring-black ring-opacity-5 focus:outline-none dark:border dark:border-white/[0.1] dark:bg-neutral-800 sm:ring-1">
              {commands.map((command) => (
                <Listbox.Option key={command} value={command}>
                  {({ active, selected }) => (
                    <div
                      className={classNames(
                        "flex flex-row gap-1 px-2 py-1 pr-6",
                        active ? "bg-yellow-400 dark:bg-yellow-500" : ""
                      )}
                    >
                      {selected && (
                        <CheckIcon
                          className={classNames(
                            "w-4 stroke-[2pt]",
                            defaultLabelStyle({
                              selectedBackground: active,
                              ...partialLabelState,
                            })
                          )}
                        />
                      )}
                      {!selected && <span className="w-4" />}
                      <Label title={command} selectedBackground={active} />
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
        <Button primary disabled={!canSend} onClick={sendMessage}>
          Send
        </Button>
      </div>
      <div className="bg-stone-50 px-2 pt-2 pb-1 dark:bg-neutral-800">
        <textarea
          value={log}
          className="dark:border-1 h-72 w-full whitespace-pre rounded-md border-0 bg-neutral-700 font-mono text-xs text-white dark:border dark:border-neutral-600 dark:bg-neutral-900 dark:text-neutral-300"
          readOnly
        />
      </div>
    </Card>
  );
};

export default SendRPC;
