import ControlSize from "./ControlSize";

type CheckboxState = {
  checked: boolean;
  disabled: boolean;
  size: ControlSize;
  loading: boolean;
};

type CheckboxStyleFactory = (state: CheckboxState) => string;

const defaultCheckboxStyle: CheckboxStyleFactory = (state) => {
  var styles: string[] = [];

  if (state.loading) {
    styles.push("animate-pulse border-0");
  } else {
    styles.push("border disabled:opacity-50");
  }

  switch (state.size) {
    case ControlSize.Small:
      styles.push("w-4 h-4 sm:w-3.5 sm:h-3.5 rounded");
      break;
    case ControlSize.Medium:
      styles.push("w-5 h-5 sm:w-4 sm:h-4 rounded-md sm:rounded");
      break;
    case ControlSize.Large:
      styles.push("w-6 h-6 sm:w-5 sm:h-5 rounded-lg sm:rounded-md");
      break;
  }

  if (state.loading) {
    styles.push("text-stone-300 bg-stone-300 !bg-none");
    styles.push("dark:text-neutral-500/[0.5] dark:bg-neutral-500/[0.5]");
  } else if (state.checked) {
    styles.push("text-yellow-400 !border-yellow-600/[0.25]");
    styles.push("dark:text-yellow-500 !border-yellow-900/[0.25]");
  } else {
    styles.push("bg-white border-stone-300");
    styles.push("dark:bg-neutral-500/[0.5] dark:border-neutral-900");
  }

  if (!state.loading && !state.disabled) {
    styles.push(
      state.checked
        ? "hover:active:text-yellow-500"
        : "hover:active:bg-stone-50"
    );
    styles.push(
      state.checked
        ? "dark:hover:active:text-yellow-400"
        : "dark:hover:active:bg-neutral-500/[0.75]"
    );
  }

  return styles.join(" ");
};

export type { CheckboxState, CheckboxStyleFactory };
export { defaultCheckboxStyle };
