import { API } from "aws-amplify";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Card from "../Card";
import ControlSize from "../controls/ControlSize";
import Button from "../controls/Button";
import Label from "../controls/Label";
import LoadingContext from "../controls/LoadingContext";
import DiagnosticSession from "../data/DiagnosticSession";
import { useAPIGet } from "../data/useAPIGet";
import DiagnosticSessionDialog from "./DiagnosticSessionDialog";
import DiagnosticSessionTable from "./DiagnosticSessionTable";

const RequestAccountLogsButton = (props: { syngID: string }) => {
  const [isLoadingPreference, setIsLoadingPreference] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [allowsDiagnostics, setAllowsDiagnostics] = useState(true);

  // reset when syngID changes
  useEffect(() => {
    setIsLoadingPreference(true);
    setIsRequesting(false);
    setAllowsDiagnostics(true);
  }, [props.syngID]);

  const preferenceCallback = useCallback((object: any) => {
    console.log("Got preference:", object);
    if (typeof object === "boolean") {
      setAllowsDiagnostics(object);
    }

    setIsLoadingPreference(false);
  }, []);
  useAPIGet(
    preferenceCallback,
    `/users/${props.syngID}/allowsAutomaticDiagnostics`
  );

  const requestLogs = async () => {
    console.log("Requesting logs");
    setIsRequesting(true);

    try {
      const result = await API.post(
        "dashboard",
        `/users/${props.syngID}/startDiagnosticSession`,
        {}
      );
      console.log("Result: ", result);
    } catch (error) {
      console.log("Error starting session: ", error);
    }

    setIsRequesting(false);
  };

  const disableRequests =
    isLoadingPreference || isRequesting || !allowsDiagnostics;

  return (
    <Button onClick={requestLogs} disabled={disableRequests} capsule>
      Request Logs
    </Button>
  );
};

const RequestCellLogsButton = (props: { syngID: string }) => {
  const [isShowingDialog, setShowingDialog] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const canRequest = serialNumber.length !== 0 && !isRequesting && isShowingDialog;

  const showDialog = useCallback(() => {
    setSerialNumber("");
    setIsRequesting(false);
    setShowingDialog(true);
  }, [setShowingDialog, setSerialNumber]);

  const closeDialog = useCallback(() => {
    setShowingDialog(false);
  }, [setShowingDialog]);

  const serialNumberChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSerialNumber(event.currentTarget.value);
    },
    [setSerialNumber]
  );

  const requestLogs = useCallback(async () => {
    setIsRequesting(true);
    console.log(`Requesting logs from ${serialNumber}`);

    try {
      const body = {
        serial_number: serialNumber
      };
      const response = await API.post("dashboard", `/users/${props.syngID}/captureCellLogs`, { body });
      console.log(`Response: ${JSON.stringify(response)}`);
    } catch (error) {
      console.error(error);
    }

    setIsRequesting(false);
    setShowingDialog(false);
  }, [serialNumber, setIsRequesting, setShowingDialog]);

  return (
    <>
      <Button onClick={showDialog} disabled={isShowingDialog} capsule>
        Request Logs
      </Button>
      <Transition show={isShowingDialog} as={Fragment}>
        <Dialog onClose={closeDialog}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/40 dark:bg-black/60"
              aria-hidden="true"
            />
          </Transition.Child>
          <div className="fixed inset-0 flex flex-col items-center justify-end py-4 sm:justify-center sm:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-16 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md">
                <Card className="p-2 grid grid-cols-3 gap-2">
                <input
                  placeholder="Serial Number"
                  value={serialNumber}
                  onChange={serialNumberChanged}
                  disabled={isRequesting}
                  className="col-span-2 rounded-lg bg-white px-6 py-2 !pl-3 !pr-1 text-lg font-medium placeholder:text-stone-300 shadow ring-1 ring-black/[0.08] focus:outline-none disabled:opacity-50 dark:bg-neutral-500/[0.5] dark:text-neutral-100 dark:placeholder:text-neutral-400 dark:ring-black/[0.18] dark:disabled:opacity-30 sm:rounded-md sm:px-4 sm:py-1.5 sm:text-sm"
                />
                <Button primary disabled={!canRequest} onClick={requestLogs} fullWidth={true}>
                  Request Logs
                </Button>
                </Card>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const DiagnosticsPanel = (props: { syngID: string }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sessions, setSessions] = useState<DiagnosticSession[]>([]);

  // FIXME: less hack
  const isSpecialAccount = props.syngID == "canned-demo-logs"

  // reset when syngID changes
  useEffect(() => {
    setIsLoading(true);
    setSessions([]);
  }, [props.syngID]);

  const sessionsCallback = useCallback((object: any) => {
    setSessions(object as DiagnosticSession[]);
    setIsLoading(false);
  }, []);
  useAPIGet(sessionsCallback, `/users/${props.syngID}/diagnosticSessions`);

  return (
    <div className="divide-y divide-stone-100 dark:divide-neutral-600">
      <div className="flex items-center justify-between bg-white px-3 py-3 dark:bg-neutral-700 sm:px-6">
        <Label size={ControlSize.Large} title="Diagnostics" loading={false} />
        { !isSpecialAccount && <RequestAccountLogsButton syngID={props.syngID} /> }
        { isSpecialAccount && <RequestCellLogsButton syngID={props.syngID} /> }
      </div>
      <div className="bg-stone-50 px-3 py-3 dark:bg-neutral-800 sm:px-6">
        <LoadingContext.Provider value={isLoading}>
          <DiagnosticSessionTable sessions={sessions} />
        </LoadingContext.Provider>
      </div>
      <DiagnosticSessionDialog sessions={sessions} />
    </div>
  );
};

export default DiagnosticsPanel;
