import React, { useState } from "react";
import { useAPIGet } from "../data/useAPIGet";
import Card from "../Card";
import ControlSize from "../controls/ControlSize";
import Label from "../controls/Label";
import config from "../../config.json";
import { ExternalLinkIcon } from "@heroicons/react/outline";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type TaskState =
  | "PROVISIONING"
  | "PENDING"
  | "ACTIVATING"
  | "RUNNING"
  | "DEACTIVATING"
  | "STOPPING"
  | "DEPROVISIONING"
  | "STOPPED";

type TaskHealth = "HEALTHY" | "UNHEALTHY" | "UNKNOWN";

type ContainerStatus = {
  task: string;
  version: string;
  status: TaskState;
  health: TaskHealth;
};

type StatusByService = {
  [key: string]: ContainerStatus[] | undefined;
};

type ServiceInfo = {
  containerName: string;
  projectPath: string;
};

const env = config.deployment_name;
const services: { [key: string]: ServiceInfo } = {
  analytics: {
    containerName: "analytics-service",
    projectPath: "analytics-service",
  },

  auth: {
    containerName: `${env}-auth-service`,
    projectPath: "auth-service",
  },

  cell_mqtt: {
    containerName: `${env}-cell-mqtt-service`,
    projectPath: "emqx-rel",
  },

  cell_registry: {
    containerName: `${env}-cell-registry`,
    projectPath: "cell-registry",
  },

  dashboard: {
    containerName: `dashboard-api-${env}`,
    projectPath: "dashboard",
  },

  death_metal: {
    containerName: "death-metal-service",
    projectPath: "death-metal-service",
  },

  health: {
    containerName: "health-service",
    projectPath: "health-service",
  },

  messaging: {
    containerName: `${env}-messaging-service`,
    projectPath: "messaging-service",
  },

  preferences: {
    containerName: `preferences-service-${env}`,
    projectPath: "preferences-service",
  },

  user_mqtt: {
    containerName: `${env}-user-mqtt-service`,
    projectPath: "emqx-rel",
  },
};

function commitURL(path: string, version: string): string {
  return `https://gitlab.com/syngoutloud/cloud-services/${path}/-/tree/${version}`;
}

function taskURL(task: string): string {
  return `https://us-west-1.console.aws.amazon.com/ecs/v2/clusters/${config.deployment_name}-cloud-services/tasks/${task}`;
}

enum BadgeColor {
  Green = "green",
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
  Purple = "purple",
  Blue = "blue",
  Gray = "gray",
}

function badgeColorClasses(color: BadgeColor): string {
  switch (color) {
    case BadgeColor.Green:
      return "bg-green-200 text-green-800 dark:bg-green-700 dark:text-green-100";
    case BadgeColor.Yellow:
      return "bg-yellow-200 text-yellow-800 dark:bg-yellow-600 dark:text-yellow-100";
    case BadgeColor.Red:
      return "bg-red-200 text-red-800 dark:bg-red-700 dark:text-red-100";
    case BadgeColor.Purple:
      return "bg-purple-200 text-purple-800 dark:bg-purple-700 dark:text-purple-100";
    case BadgeColor.Blue:
      return "bg-blue-200 text-blue-800 dark:bg-blue-700 dark:text-blue-100";
    case BadgeColor.Gray:
      return "bg-stone-200 text-stone-800 dark:bg-neutral-600 dark:text-stone-400";
  }

  return "";
}

const Badge = (props: { color: BadgeColor; children?: React.ReactNode }) => {
  return (
    <div
      className={classNames(
        "rounded px-2 py-0.5 text-xs font-medium capitalize",
        badgeColorClasses(props.color)
      )}
    >
      {props.children}
    </div>
  );
};

Badge.defaultProps = {
  color: BadgeColor.Gray,
};

function colorForHealth(health: TaskHealth): BadgeColor {
  switch (health) {
    case "HEALTHY":
      return BadgeColor.Green;
    case "UNHEALTHY":
      return BadgeColor.Red;
    case "UNKNOWN":
      return BadgeColor.Yellow;
  }
}

const TaskHealthIndicator = (props: { health: TaskHealth }) => {
  return (
    <Badge color={colorForHealth(props.health)}>
      {props.health.toLowerCase()}
    </Badge>
  );
};

function colorForState(state: TaskState): BadgeColor {
  switch (state) {
    case "RUNNING":
      return BadgeColor.Green;
    case "ACTIVATING":
    case "DEACTIVATING":
      return BadgeColor.Yellow;
    case "PENDING":
    case "STOPPING":
      return BadgeColor.Purple;
    case "PROVISIONING":
    case "DEPROVISIONING":
      return BadgeColor.Blue;
    case "STOPPED":
      return BadgeColor.Red;
  }
}

const TaskStateIndicator = (props: { state: TaskState }) => {
  return (
    <Badge color={colorForState(props.state)}>
      {props.state.toLowerCase()}
    </Badge>
  );
};

const ServiceRow = (props: {
  name: string;
  containers: ContainerStatus[];
  projectPath: string;
}) => {
  return (
    <div className="flex flex-row items-baseline justify-between gap-2 py-2">
      <Label title={props.name} size={ControlSize.Large} />
      <div className="flex flex-col gap-2">
        {props.containers.map((container, i) => (
          <div key={i} className="flex items-baseline gap-2">
            <a
              href={taskURL(container.task)}
              target="blank"
              className="none flex items-center gap-2"
            >
              <TaskHealthIndicator health={container.health} />
              <TaskStateIndicator state={container.status} />
            </a>
            <a
              href={commitURL(props.projectPath, container.version)}
              target="blank"
              className="none flex items-center"
            >
              <Label
                as="span"
                title={container.version}
                className="font-semibold !text-blue-500"
                mono
              />
              <ExternalLinkIcon className="w-4 stroke-[1.7pt] text-blue-500" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const SystemStatus = () => {
  const [containers, setContainers] = useState<StatusByService>({});
  useAPIGet((result: any) => {
    setContainers(result);
  }, "/status");

  const serviceNames = Object.keys(services);
  serviceNames.sort();

  return (
    <Card className="m-auto mt-8 w-96 divide-y divide-stone-100 dark:divide-neutral-600 sm:w-1/2">
      <div className="bg-white px-3 py-3 dark:bg-neutral-700 sm:px-6">
        <Label title="System Status" size={ControlSize.Large} />
      </div>
      <div className="divide-y divide-stone-200 bg-stone-50 px-4 py-2 dark:divide-neutral-700 dark:bg-neutral-800 sm:px-6">
        {serviceNames.map((service) => (
          <ServiceRow
            key={service}
            name={service}
            projectPath={services[service].projectPath}
            containers={containers[services[service].containerName] ?? []}
          />
        ))}
      </div>
    </Card>
  );
};

export default SystemStatus;
