import React from "react";
import { Routes, Route } from "react-router-dom";
import { Example } from "./Example";
import { ExampleFrame } from "./ExampleFrame";

import Buttons from "./testers/Buttons";
import Checkboxes from "./testers/Checkboxes";
import Controls from "./testers/Controls";
import WebSocket from "./testers/WebSocket";

function Testers() {
  return (
    <Routes>
      <Route path="controls" element={<Controls />} />

      <Route path="ws" element={<WebSocket />} />

      <Route path="buttons" element={<Buttons />} />
      <Route path="checkboxes" element={<Checkboxes />} />
    </Routes>
  );
}

function ResizableTesters() {
  return (
    <div className="m-10">
      <Example resizable lightOnly>
        <ExampleFrame title="" className="h-96 w-full">
          <div className="w-full">
            <Testers />
          </div>
        </ExampleFrame>
      </Example>
    </div>
  );
}

function Tester() {
  return (
    <Routes>
      <Route path="/*" element={<Testers />} />
      <Route path="/resizable/*" element={<ResizableTesters />} />
    </Routes>
  );
}

export default Tester;
