import { useCallback, useState } from "react";
import { useAPIGet } from "./useAPIGet";
import { useTopicEvent } from "../socket/ChannelProvider";
import Data from "./Data";

type CellData = {
  serial_number: string;
  online: boolean;
  model?: string;
  syng_os_version?: string;

  role: string;

  ssid?: string;
  rssi?: number;
  wifi_channel?: number;
};

const placeholderCellData: CellData = {
  serial_number: "XXXXXXXXXXXXXXXXX",
  online: true,
  model: "C01_MP1",
  syng_os_version: "Troubadour-1.4",
  role: "primary",
  ssid: "SYNG",
  rssi: -50,
  wifi_channel: 149,
};

const useCellData = (serialNumber: string): Data<CellData> => {
  const [data, setData] = useState<Data<CellData>>({ state: "LOADING" });
  const callback = useCallback((object: any) => {
    setData({ state: "LOADED", value: object });
  }, []);

  useAPIGet(callback, `/cells/${serialNumber}`);
  useTopicEvent(`cell:${serialNumber}`, "changed", callback);

  return data;
};

export default CellData;
export { placeholderCellData, useCellData };
