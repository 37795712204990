import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

export const ExampleFrame = ({ children, title, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const [mountNode, setMountNode] = useState(null);

  useEffect(() => {
    if (!contentRef) {
      return;
    }

    const win = contentRef?.contentWindow;

    const oldStyleElements = win.document.querySelectorAll("style");
    oldStyleElements.forEach((el) => el.remove());

    const styleElements = win.parent.document.querySelectorAll("style");
    styleElements.forEach((el) => {
      win.document.head.appendChild(el.cloneNode(true));
    });
  }, [mountNode]);

  const handleLoad = () => {
    if (!contentRef) {
      return;
    }

    setMountNode(contentRef.contentWindow?.document.body.children[0]);
  };

  return (
    <iframe
      title={title}
      {...props}
      ref={setContentRef}
      srcDoc='<!DOCTYPE html><html lang="en" class="h-full bg-stone-100"><body class="w-full h-full"><div></div></body></html>'
      onLoad={handleLoad}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
