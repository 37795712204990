import React, { useEffect, useState } from "react";

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

export enum ThemePreference {
  light = "light",
  dark = "dark",
  auto = "auto",
}

function shouldUseDark(
  themePreference: ThemePreference,
  isOSDark: boolean
): boolean {
  switch (themePreference) {
    case ThemePreference.dark:
      return true;
    case ThemePreference.light:
      return false;
    case ThemePreference.auto:
      return isOSDark;
  }
}

function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string | null,
  defaultValue: T
): T {
  if (!value) {
    return defaultValue;
  }
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : defaultValue;
}

export const ThemeContext = React.createContext({
  theme: ThemePreference.auto,
  setTheme: (_pref: ThemePreference) => {},
});

const ThemeProvider = (props: { children: React.ReactNode }) => {
  const isOSDark = useMediaQuery("(prefers-color-scheme: dark)");
  const [themePreference, setThemePreference] = useState(
    enumFromStringValue(
      ThemePreference,
      localStorage.getItem("themePreference"),
      ThemePreference.auto
    )
  );
  const isDark = shouldUseDark(themePreference, isOSDark);

  useEffect(() => {
    localStorage.setItem("themePreference", themePreference);
  }, [themePreference]);

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDark]);

  return (
    <ThemeContext.Provider
      value={{ theme: themePreference, setTheme: setThemePreference }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
