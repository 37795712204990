import { XIcon } from "@heroicons/react/outline";

const CloseButton = (props: { onClick?: () => void }) => {
  return (
    <button onClick={props.onClick}>
      <XIcon className="h-6 w-6" />
    </button>
  );
};

export default CloseButton;
