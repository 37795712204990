import { useParams } from "react-router-dom";

import ControlSize from "./controls/ControlSize";
import Label from "./controls/Label";
import LoadingContext from "./controls/LoadingContext";

import CellData, { placeholderCellData, useCellData } from "./data/CellData";

type CellParams = {
  serialNumber: string;
};

function formatOSVersion(version?: string): string | undefined {
  if (version) {
    return version.replace(/^(SyngOS|Troubadour)-/, "");
  }

  return undefined;
}

function formatRSSI(cell: CellData): string | undefined {
  if (cell.rssi) {
    return String(cell.rssi);
  }

  return undefined;
}

function formatWiFiStrength(cell: CellData): string | undefined {
  if (cell.rssi) {
    return String(Math.min(100, (cell.rssi + 100) * 2)) + "%";
  }

  return undefined;
}

type RowProps = {
  title: string;
  value?: string;
};

const Row = (props: RowProps) => {
  const isMissing = props.value === undefined;
  return (
    <div className="flex justify-between py-3 text-sm font-medium">
      <dt>
        <Label secondary loading={undefined} title={props.title} />
      </dt>
      <dd>
        <Label
          secondary={isMissing}
          disabled={isMissing}
          title={props.value ?? "—"}
        />
      </dd>
    </div>
  );
};

type CellInfoProps = {
  cell: CellData;
};

const CellInfo = (props: CellInfoProps) => {
  return (
    <aside className="m-auto mt-8 w-96 divide-y divide-stone-100 overflow-hidden rounded-xl bg-white shadow dark:divide-neutral-600 dark:border dark:border-white/[0.01] dark:bg-neutral-700 dark:shadow-[0_10px_15px_-3px_rgba(0,0,0,0.15)]">
      <div className="px-4 py-5 sm:px-6">
        <Label size={ControlSize.Large} title={props.cell.serial_number} />
      </div>
      <div
        className="bg-stone-50 px-4 py-2 dark:bg-neutral-800 sm:px-6"
        onClick={() => console.log("toggle advanced")}
      >
        <dl className="divide-y divide-stone-200 dark:divide-neutral-700">
          <Row title="Model" value={props.cell.model} />
          <Row
            title="Status"
            value={props.cell.online ? "Online" : "Offline"}
          />
          <Row
            title="OS Version"
            value={formatOSVersion(props.cell.syng_os_version)}
          />
          <Row title="Role" value={props.cell.role} />
          <Row title="SSID" value={props.cell.ssid} />
          <Row title="RSSI" value={formatRSSI(props.cell)} />
          <Row title="Wi-Fi Strength" value={formatWiFiStrength(props.cell)} />
          <Row title="Wi-Fi Channel" value={String(props.cell.wifi_channel)} />
        </dl>
      </div>
    </aside>
  );
};

function Cell() {
  const { serialNumber } = useParams<CellParams>();
  const cellData = useCellData(serialNumber ?? "");
  const currentCellData =
    cellData.state === "LOADED" ? cellData.value : placeholderCellData;
  const isLoading = cellData.state === "LOADING";

  return (
    <LoadingContext.Provider value={isLoading}>
      <CellInfo cell={currentCellData} />
    </LoadingContext.Provider>
  );
}

export default Cell;
