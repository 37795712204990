import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";

import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { CognitoUser } from "@aws-amplify/auth";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import Button from "./controls/Button";
import ControlSize from "./controls/ControlSize";

class User {
  private cognitoUser?: CognitoUser;
  private userAttributes: CognitoUserAttribute[];
  private groups: string[];

  constructor(
    cognitoUser: CognitoUser | undefined = undefined,
    userAttributes: CognitoUserAttribute[] = []
  ) {
    this.cognitoUser = cognitoUser;
    this.userAttributes = userAttributes;
    this.groups = [];

    if (cognitoUser) {
      const session = cognitoUser.getSignInUserSession();
      if (session) {
        const payload = session.getAccessToken().payload;
        const groups = payload["cognito:groups"];
        if (Array.isArray(groups)) {
          this.groups = groups;
        }
      }
    }
  }

  get email(): string | null {
    const attribute = this.userAttributes.find(
      (attribute) => attribute.Name === "email"
    );

    if (attribute) {
      return attribute.Value;
    }

    return null;
  }

  get isAdmin(): boolean {
    return this.groups.includes("admin");
  }

  get canReadCustomerData(): boolean {
    return this.groups.includes("customer_data_read");
  }
}

const UserContext = React.createContext(new User());

const AuthProvider = (props: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [hasLoaded, setHasLoaded] = useState(false);
  const savedLocationKey = "AuthProvider.savedLocation";

  function setCognitoUser(cognitoUser?: CognitoUser) {
    if (cognitoUser) {
      cognitoUser.getUserAttributes((_err, attributes) => {
        if (attributes) {
          setUser(new User(cognitoUser, attributes));
          setHasLoaded(true);
        } else {
          setUser(new User(cognitoUser, []));
        }
      });
    } else {
      setUser(undefined);
      setHasLoaded(true);
    }
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log("Auth event: ", event, data);
      switch (event) {
        case "signIn":
          setCognitoUser(data);
          const saved = sessionStorage.getItem(savedLocationKey);
          if (saved) {
            sessionStorage.removeItem(savedLocationKey);
            window.location.href = saved;
          }
          break;
        case "signOut":
          setCognitoUser(undefined);
          break;
        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setCognitoUser(currentUser);
      })
      .catch(() => {
        setCognitoUser(undefined);
      });

    return unsubscribe;
  }, []);

  const signIn = async () => {
    sessionStorage.setItem(savedLocationKey, window.location.href);
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  if (!hasLoaded) {
    return <></>;
  }

  if (user) {
    return (
      <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
    );
  }

  return (
    <>
      <div className="flex h-full w-full justify-center">
        <div className="m-auto">
          <Button capsule size={ControlSize.Large} onClick={signIn}>
            Sign In
          </Button>
        </div>
      </div>
    </>
  );
};

AuthProvider.Context = UserContext;

export default AuthProvider;
