import { useContext } from "react";
import LoadingContext from "./LoadingContext";

import { ReactComponent as WiFi1 } from "../../assets/svg/WiFi1.svg";
import { ReactComponent as WiFi2 } from "../../assets/svg/WiFi2.svg";
import { ReactComponent as WiFi3 } from "../../assets/svg/WiFi3.svg";
import { ReactComponent as WiFiLoading } from "../../assets/svg/WiFiLoading.svg";
import { ReactComponent as WiFiOffline } from "../../assets/svg/WiFiOffline.svg";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function numberOfSignalBars(rssi: number, channel: number): number {
  const is5GHz = channel > 14;
  const goodRSSI = is5GHz ? -80 : -60;
  const poorRSSI = is5GHz ? -85 : -70;

  if (rssi >= goodRSSI) {
    return 3;
  }

  if (rssi >= poorRSSI) {
    return 2;
  }

  return 1;
}

const WiFiIndicator = (props: {
  rssi: number;
  channel: number;
  isOnline: boolean;
  selectedBackground?: boolean;
}) => {
  const isLoading = useContext(LoadingContext);
  const bars = numberOfSignalBars(props.rssi, props.channel);
  const images = [WiFi1, WiFi2, WiFi3];
  const selectedBackground = props.selectedBackground ?? false;

  const filledClasses = selectedBackground
    ? "text-black dark:text-white"
    : "text-green-500";
  const unfilledClasses = selectedBackground
    ? "text-black/50 dark:text-white/50"
    : "text-gray-500";

  if (isLoading) {
    return (
      <div className="relative h-5 w-5">
        <WiFiLoading className="absolute h-5 w-5 animate-pulse text-stone-300 dark:text-neutral-500" />
      </div>
    );
  }

  if (!props.isOnline) {
    return (
      <div className="relative h-5 w-5">
        <WiFiOffline className="absolute h-5 w-5 text-stone-500 dark:text-neutral-500" />
      </div>
    );
  }

  return (
    <div className="relative h-5 w-5">
      {[1, 2, 3].map((i) => {
        const Image = images[i - 1];
        return (
          <Image
            key={i}
            className={classNames(
              "absolute h-5 w-5",
              i > bars ? unfilledClasses : filledClasses
            )}
          />
        );
      })}
    </div>
  );
};

export default WiFiIndicator;
