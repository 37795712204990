import { useCallback, useState } from "react";
import { useAPIGet } from "./useAPIGet";
import { useTopicEvent } from "../socket/ChannelProvider";
import Data from "./Data";

type RoomData = {
  room_id: string;
  name: string;
};

const placeholderRoomData: RoomData = {
  room_id: "XXXXXXXXXXXXXXXXX",
  name: "Living Room",
};

const useRoomData = (roomID: string): Data<RoomData> => {
  const [data, setData] = useState<Data<RoomData>>({ state: "LOADING" });
  const callback = useCallback((object: any) => {
    setData({ state: "LOADED", value: object });
  }, []);

  useAPIGet(callback, `/rooms/${roomID}`);
  useTopicEvent(`room:${roomID}`, "changed", callback);

  return data;
};

const useRoomCells = (roomID: string): Data<string[]> => {
  const [data, setData] = useState<Data<string[]>>({ state: "LOADING" });
  const callback = useCallback((object: any) => {
    setData({ state: "LOADED", value: object });
  }, []);

  useAPIGet(callback, `/rooms/${roomID}/cells`);
  //useTopicEvent(`room:${roomID}`, "changed", callback);

  return data;
};

export default RoomData;
export { placeholderRoomData, useRoomCells, useRoomData };
