import React, { useContext } from "react";
import {
  ButtonState,
  ButtonStyleFactory,
  defaultButtonStyle,
} from "./ButtonStyleFactory";
import ControlSize from "./ControlSize";
import LoadingContext from "./LoadingContext";

type ButtonProps = {
  primary: boolean;
  disabled: boolean;
  size: ControlSize;
  fullWidth: boolean;
  capsule: boolean;
  loading?: boolean;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style: ButtonStyleFactory;
};

const Button = (props: ButtonProps) => {
  const isLoadingViaContext = useContext(LoadingContext);
  const isLoading = props.loading ?? isLoadingViaContext;

  const state: ButtonState = {
    primary: props.primary,
    disabled: props.disabled,
    size: props.size,
    fullWidth: props.fullWidth,
    capsule: props.capsule,
    loading: isLoading,
  };

  return (
    <button
      type="button"
      disabled={isLoading || props.disabled}
      onClick={props.onClick}
      className={props.style(state)}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  primary: false,
  disabled: false,
  size: ControlSize.Medium,
  fullWidth: false,
  capsule: false,
  loading: null,
  style: defaultButtonStyle,
};

export { defaultButtonStyle };
export default Button;
