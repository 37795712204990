import { Fragment, useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { usePlaceData } from "../data/PlaceDataProvider";
import { useAPIGet } from "../data/useAPIGet";
import { UserHeader, useUser } from "../User";
import Card from "../Card";
import LoadingContext from "../controls/LoadingContext";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";

const UserLink = ({ syngID }: { syngID: string }) => {
  const user = useUser(syngID);

  return (
      <LoadingContext.Provider value={user.isLoading}>
        <Link to={`/users/${syngID}`}>
          <div className="flex justify-between items-center px-3 py-3 sm:px-6">
            <UserHeader user={user.value} selectedBackground={true} inline={true} />
            <ArrowCircleRightIcon className="h-6 -mx-3 text-yellow-500" />
          </div>
        </Link>
      </LoadingContext.Provider>
  );
};

const PlaceUserLinks = () => {
  const placeData = usePlaceData();
  const [users, setUsers] = useState<string[]>([]);
  const [isExpanded, setExpanded] = useState(false);

  const callback = useCallback((u) => {
    setUsers(u);
    setExpanded((u as string[]).length != 0);
  }, [setUsers]);
  useAPIGet(callback, placeData.isLoading ? undefined : `/organizations/${placeData.organizationID}/users`);

  return (
    <div className={`col-span-3 flex flex-col justify-end transition-all ${isExpanded ? "h-12" : "h-0"}`}>
      <Transition
        show={isExpanded}
        enter="transition-opacity"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        >
          <Card className="ring-2 ring-yellow-500">
            <div className="divide-y divide-stone-100 dark:divide-neutral-600">
              <div className="min-w-full bg-white dark:bg-neutral-800">
                {users.map((user) => (
                  <UserLink syngID={user} key={user} />
                ))}
              </div>
            </div>
          </Card>
      </Transition>
    </div>
  );
};

export default PlaceUserLinks;
