import { Amplify, Auth } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import AuthProvider from "./components/AuthProvider";
import Cell from "./components/Cell";
import Fetch from "./components/Fetch";
import Place from "./components/place/Place";
import Room from "./components/Room";
import Tester from "./components/Tester";
import ThemeProvider from "./components/ThemeProvider";
import User from "./components/User";

import ChannelProvider from "./components/socket/ChannelProvider";

import AdminArea from "./components/admin/AdminArea";
import CannedDemo from "./components/pages/CannedDemo";
import Users from "./components/admin/Users";
import SendRPC from "./components/pages/SendRPC";
import SerialNumberInfo from "./components/SerialNumberInfo";
import SystemStatus from "./components/pages/SystemStatus";

import config from "./config.json";

import "./index.css";

Amplify.configure({
  region: "us-west-1",
  identityPoolRegion: "us-west-1",
  userPoolId: config.user_pool_id,
  userPoolWebClientId: config.user_pool_web_client_id,
  identityPoolId: config.identity_pool_id,

  oauth: {
    redirectSignIn: config.auth_redirect_url,
    redirectSignOut: config.auth_redirect_url,
    domain: config.oauth_domain,
    scope: ["email", "openid", "aws.cognito.signin.user.admin"],
    responseType: "code",
  },

  API: {
    endpoints: [
      {
        name: "dashboard",
        endpoint: config.api_url,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <ChannelProvider>
          <Router>
            <Routes>
              <Route path="/" element={<App />}>
                <Route path="status" element={<SystemStatus />} />
                <Route path="canned-demo" element={<CannedDemo />} />
                <Route path="rpc" element={<SendRPC />} />

                <Route path="fetch" element={<Fetch />} />

                <Route path="cells">
                  <Route path=":serialNumber" element={<Cell />} />
                </Route>

                <Route path="rooms">
                  <Route path=":roomID" element={<Room />} />
                </Route>

                <Route path="places">
                  <Route path=":placeID/*" element={<Place />} />
                </Route>

                <Route path="users">
                  <Route path=":syngID/*" element={<User />} />
                </Route>

                <Route path="tools">
                  <Route path="sn" element={<SerialNumberInfo />} />
                </Route>

                <Route path="admin" element={<AdminArea />}>
                  <Route path="users" element={<Users />} />
                  <Route path="rpc" element={<Navigate replace to="/rpc" />} />
                </Route>
              </Route>

              <Route path="/tester/*" element={<Tester />} />
            </Routes>
          </Router>
        </ChannelProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
